import { IonContent, IonPage } from "@ionic/react";
import CommingSoon from "../components/CommingSoon";
import TopBar from "../components/TopBar";
import LoggedInRoute from "../tools/LoggedInRoute";

const Notification: React.FC = () => {
    return (
        <LoggedInRoute>
            <IonPage>
                <TopBar title={null} />
                <IonContent fullscreen scrollY={false}>
                    <div className="container page--notification">
                        <CommingSoon title="Bientôt disponible !" type="full" />
                    </div>
                </IonContent>
            </IonPage>
        </LoggedInRoute>
    )
}

export default Notification;