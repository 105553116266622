import { useState, useEffect } from "react";
import { IonBadge, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonList, useIonViewWillEnter } from "@ionic/react";
import { NOT_FOUND, spoil_feed } from "../tools/content";
import { useAuth } from "../context/AuthContext";
import SpoilCard from "./SpoilCard";
import Loader from "./loader";
import CommingSoon from "./CommingSoon";

interface SpoilFeedProps {
    type: "default" | "title" | "user",
    username: string | null,
    titleId: string | null
};

const SpoilFeed = (props: SpoilFeedProps) => {

    const [disableLoader, setDisableLoader] = useState<boolean>(false);
    const [spoils, setSpoils] = useState<Array<any>>([]);
    const [isLoading, setIsloading] = useState<boolean>(true);
    const [maxDate, setMaxDate] = useState<Date | null>(null);
    const [page, setPage] = useState<number>(1);
    const [error, setError] = useState<string | null>(null);
    const { authState } = useAuth();

    // Function used to display the data
    const pushData = (data: Array<Object>) => {
        const newData: Array<any> = [];
        data.forEach((item) => {
            newData.push(item);
        });
        setSpoils([
            ...spoils,
            ...newData
        ]);
    }

    const loadData = async () => {
        let date: Date = new Date();
        if (maxDate === null) {
            setMaxDate(date);
        } else {
            date = maxDate;
        }

        //Call the api
        const response: any = await spoil_feed(page, props.type, maxDate ? maxDate : new Date(), authState.userToken, props.username, props.titleId);

        //Check if we have data
        if (response.data) {

            //Check if we are at the end
            if (response.last_page <= page) {
                //We are at the end
                setDisableLoader(true);
            } else {
                setDisableLoader(false);
            }

            //Push data
            pushData(response.data);

            //Change page
            setPage(response.current_page + 1);
        } else {
            //The was an error
            setDisableLoader(true);
            switch (response) {
                case NOT_FOUND: {
                    setError('404');
                    break;
                }
                default: {
                    setError('Une erreur est survenue');
                    break;
                }
            }
        }
    }

    const loadMore = (ev: any) => {
        loadData().then(() => {
            ev.target.complete();
        });
    }

    //Set up
    const boot = () => {
        setSpoils([]);
        setError(null);
        setIsloading(true);
        setDisableLoader(false);
        setPage(1);
        setMaxDate(null);
        loadData().then(() => {
            setIsloading(false);
        });
    }

    useIonViewWillEnter(() => {
        boot()
    });

    useEffect(() => {
        boot();
    }, [props.type, props.titleId, props.username, authState.userToken]);

    return (
        <section className="spoilList">
            {
                isLoading ? (
                    <Loader type="in-content" />
                ) : (
                    <>
                        {
                            spoils.length > 0 ? (
                                <IonList color="transparent" style={{ background: '#000000' }}>
                                    {
                                        spoils.map(spoil => (
                                            <SpoilCard key={spoil.id} content={spoil.content} sender={spoil.user.username} title={spoil.title.title} />
                                        ))
                                    }
                                </IonList>
                            ) : props.type === 'user' ? (
                                <CommingSoon title={`Il n'y a aucun spoil pour l'utilisateur ${props.username}`} type="in-content" />
                            ) : props.type === 'title' ? (
                                <CommingSoon title={"Il n'y a aucun spoil pour ce titre !"} type="in-content" />
                            ) : authState.userToken ? (
                                <CommingSoon title={"Votre feed est vide !\nCommencer à suivre d'autres utilisateur pour le remplir !"} type="in-content" />
                            ) : (
                                <IonBadge color="primary">
                                    Une erreur est survenue ...
                                </IonBadge>
                            )
                        }
                        <IonInfiniteScroll
                            onIonInfinite={loadMore}
                            threshold="100px"
                            disabled={disableLoader}>
                            <IonInfiniteScrollContent>
                                <Loader type="in-content" />
                            </IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                    </>
                )
            }
        </section>
    );
}

export default SpoilFeed;