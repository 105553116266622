import { IonContent, IonPage } from '@ionic/react';
import LastUpdate from '../components/LastUpdate';
import SpoilFeed from '../components/SpoilFeed';
import TopBar from '../components/TopBar';
const Home: React.FC = () => {
  return (
    <IonPage>
      <TopBar title={null} />
      <IonContent fullscreen>
        <LastUpdate />
        <div className="spoilFeed--container">
          <SpoilFeed type="default" username={null} titleId={null} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
