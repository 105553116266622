import { Lottie } from "@crello/react-lottie";
import { Link } from "react-router-dom";
import Animation from "../resources/lottie/som_mire.json";

const NotFoundAnimation: React.FC = () => {
    return (
        <div className="not-found-animation">
            <p className="title title--large">404</p>
            <div className="spacer" />
            <Lottie className="lottie" config={{
                animationData: Animation,
                loop: true,
                autoplay: true
            }}
            />
            <div className="spacer" />
            <Link to="/" className="btn btn--red">Retour à l'acceuil</Link>
        </div>
    )
}

export default NotFoundAnimation;