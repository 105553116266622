import { useState, useEffect } from 'react';
import { IonContent, IonPage } from "@ionic/react";
import { Redirect } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { getCurrentUserDetail } from '../tools/auth';
import SpoilFeed from '../components/SpoilFeed';
import TopBar from '../components/TopBar';
import FollowButton from '../components/FollowButton';
import { useParams } from 'react-router-dom';
import { get_user, NOT_FOUND, ERROR, get_following, get_followers } from '../tools/content';
import UserCircle from '../resources/icons/circle_user.svg';
import Button from '../components/Button';
import { CardProps } from '../components/Card';
import CardList from '../components/CardList';
import Loader from '../components/loader';
import NotFoundAnimation from '../components/NotFoundAnimation';
import CommingSoon from '../components/CommingSoon';

const Profil: React.FC = () => {

  interface profilInterface {
    id: undefined | null | string,
    username: undefined | null | string,
    email: undefined | null | string,
    nb_spoil: undefined | null | number
  }

  const [profil, setProfil] = useState<profilInterface>({
    id: undefined,
    username: undefined,
    email: undefined,
    nb_spoil: undefined
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [redirect, setRedirect] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<string>('spoil');
  const [pageData, setPageData] = useState<Array<CardProps>>([]);
  const [infinitePage, setInfinitePage] = useState<number>(1);
  const [infiniteActive, setInfiniteActive] = useState<boolean>(false);
  const [infiniteLoading, setInfiniteLoading] = useState<boolean>(true);
  const { authState } = useAuth();
  let params: any = useParams();

  const getData = async (type: string, selectedPage: number = 1, clear: boolean = false) => {
    let result: any;
    switch (type) {
      case 'follow': {
        result = await get_following(profil.id ? profil.id : '', selectedPage);
        break;
      }
      case 'follower': {
        result = await get_followers(profil.id ? profil.id : '', selectedPage);
        break;
      }
      default: {
        throw new Error('Unkonw data type');
      }
    }

    //Prepare for netx request
    setInfinitePage(result.current_page + 1);
    if (result.current_page + 1 >= result.last_page) {
      setInfiniteActive(false);
    } else {
      setInfiniteActive(true);
    }

    //Push data
    const newData: Array<CardProps> = [];
    result.data.forEach((element: any) => {
      newData.push({
        title: element.username,
        subTitle: '',
        type: 'user',
        link: `/profil/${element.username}`
      });
    });

    if (clear) {
      setPageData(newData)
    } else {
      setPageData([
        ...pageData,
        ...newData
      ]);
    }
  }

  const changePage = (desiredPage: 'spoil' | 'follow' | 'follower' | 'like') => {
    if (desiredPage !== currentPage) {
      setInfiniteLoading(true);
      setInfinitePage(1);
      setPageData([]);
      setInfiniteActive(false);

      switch (desiredPage) {
        case 'spoil': {
          setCurrentPage('spoil');
          break;
        }
        case 'follow': {
          setCurrentPage('follow');
          getData('follow', 1, true).then(() => {
            setInfiniteLoading(false);
          });
          break;
        }
        case 'follower': {
          setCurrentPage('follower');
          getData('follower', 1, true).then(() => {
            setInfiniteLoading(false);
          })
          break;
        }
        case 'like': {
          setCurrentPage('like');
          break;
        }
        default: {
          throw new Error('Bad page type');
        }
      }
    }
  };

  const infiniteHandler = (ev: any) => {
    getData(currentPage, infinitePage, false).then(() => {
      ev.target.complete();
    });
  }

  const boot = () => {
    setCurrentPage('spoil');
    setPageData([]);
    setInfinitePage(1);
    setInfiniteActive(false);
    setInfiniteLoading(true);

    if (params.userId) {
      //Search user
      get_user(params.userId).then((response: any) => {
        if (response === NOT_FOUND) {
          setError('Utilisateur introuvable');
        } else if (response === ERROR) {
          setError('Une erreur est survenue');
        } else {
          setProfil({
            id: response.id,
            username: response.username,
            email: null,
            nb_spoil: response.nb_spoil
          });
        }
        setIsLoading(false);
      });
    } else {
      //Get current user
      getCurrentUserDetail(authState.userToken).then((response: any) => {
        if (response === null) {
          setRedirect('/login');
          setProfil({
            id: null,
            username: null,
            email: null,
            nb_spoil: null
          });
        } else {
          setProfil({
            id: response.id,
            username: response.username,
            email: response.email,
            nb_spoil: response.nb_spoil
          });
        }
        setIsLoading(false);
      });
    }
  }

  useEffect(() => {
    boot();
  }, [params]);

  return (
    <IonPage >
      <TopBar title={null} />
      {
        isLoading ? (
          <Loader type="full" />
        ) : redirect ? (
          <Redirect to={redirect} />
        ) : error ? (
          <NotFoundAnimation />
        ) : (
          <IonContent fullscreen scrollY={false}>
            <div className="page--profil container">
              <section className="profil--header">
                <div className="profil--image">
                  <img src={UserCircle} />
                </div>
                <h2 className="title title--large">{profil.username}</h2>
                <p className="text text--normal">Score : {profil.nb_spoil}</p>
                <FollowButton userID={profil.id ? profil.id : ''} />
                <div className="profil--selector">
                  <Button className={null} title={"Spoils"} onClick={() => { changePage('spoil') }} color={currentPage === 'spoil' ? 'red' : 'black'} />
                  <Button className={null} title={"Like"} onClick={() => { changePage('like') }} color={currentPage === 'like' ? 'red' : 'black'} />
                  <Button className={null} title={"Follow"} onClick={() => { changePage('follow') }} color={currentPage === 'follow' ? 'red' : 'black'} />
                  <Button className={null} title={"Follower"} onClick={() => { changePage('follower') }} color={currentPage === 'follower' ? 'red' : 'black'} />
                </div>
              </section>
              <section className="profil--body">
                {
                  currentPage === 'spoil' ? (
                    <SpoilFeed type="user" username={profil.username ? profil.username : ''} titleId={null} />
                  ) : currentPage === 'like' ? (
                    <CommingSoon title="Bientôt disponible !" type="in-content" />
                  ) : currentPage === 'follow' || currentPage === 'follower' ? (
                    <CardList loadMore={infiniteHandler} disabled={!infiniteActive} data={pageData} isLoading={infiniteLoading} />
                  ) : (
                    <></>
                  )
                }
              </section>
            </div>
          </IonContent>
        )
      }
    </IonPage>
  );
};

export default Profil;