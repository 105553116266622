import { IonItem } from "@ionic/react"
import { Link } from "react-router-dom";
import IconCircleUser from '../resources/icons/circle_user.svg';

interface SpoilCardProps {
    sender: string,
    content: string,
    title: string
}

const SpoilCard = (props: SpoilCardProps) => {


    return (
        <IonItem color="transparent">
            <div className="spoil">
                <div className="spoil--header">
                    <Link to="/profil" className="spoil--sender" >
                        <img src={IconCircleUser} />
                    </Link>
                    <p className="spoil--title">
                        <Link to={`/profil/${props.sender}`} className="spoil--title">{props.sender} </Link>
                        a spoilé {props.title}
                    </p>
                </div>
                <div className="spoil--body">
                    <p className="spoil--content">{props.content}</p>
                </div>
            </div>
        </IonItem>
    );
}

export default SpoilCard;