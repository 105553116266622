
interface ProgressBarProps {
    progress: number;
}

const ProgressBar = (props: ProgressBarProps) => {
    return (
        <div className="progress-bar">
            <span className={`progress-bar--state state--${props.progress}`}></span>
        </div>
    );
}

export default ProgressBar;