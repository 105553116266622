import { IonPage } from "@ionic/react";
import TopBar from "../components/TopBar";
import LoggedInRoute from "../tools/LoggedInRoute";

const UpdateUser: React.FC = () => {
    return (
        <LoggedInRoute>
            <IonPage>
                <TopBar title={null} />
            </IonPage>
        </LoggedInRoute>
    );
}

export default UpdateUser;