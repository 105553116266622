import React, { useState } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonContent, IonPage } from "@ionic/react"
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import Button from "../components/Button";
import TopBar from "../components/TopBar"
import LoggedInRoute from "../tools/LoggedInRoute"
import Loader from '../components/loader';
import { OK, updateUser } from '../tools/auth';
import { useAuth } from '../context/AuthContext';

const EditPassword: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const { authState } = useAuth();

    const onSubmit = (value: any) => {
        setIsLoading(true);
        setError(null);
        setSuccess(null);
        updateUser(authState.user_id, authState.userToken, null, null, value.password, null).then((result) => {
            if (result === OK) {
                setSuccess('Votre mot de passe à bien été modifié.');
            } else {
                setError('une erreur est survenue.');
            }
            setIsLoading(false);
        })
    }

    const validationSchema = Yup.object({
        password: Yup.string().required('Obligatoire').min(8, 'Doit contenir au minimum 8 charactères').matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,255}$/g, 'Dois contenir au minimum 1 majuscule, 1 minuscule et 1 chiffre'),
        confirm_password: Yup.string().required('Obligatoire').oneOf([Yup.ref('password'), null], 'Les mot de passes ne correspondent pas')
    });

    const initialValue = {
        password: '',
        confirm_password: ''
    };

    return (
        <LoggedInRoute>
            <IonPage>
                <TopBar title={null} />
                <IonContent>
                    <div className="container page--edit-username scroller">
                        <h2 className="title">Modifiez votre mot de passe</h2>
                        {
                            error && (
                                <IonCard color="danger">
                                    <IonCardHeader className="title">Oups</IonCardHeader>
                                    <IonCardContent>
                                        <p>{error}</p>
                                    </IonCardContent>
                                </IonCard>
                            )
                        }
                        {
                            success && (
                                <IonCard color="success">
                                    <IonCardHeader className="title title--white">C'est tout bon !</IonCardHeader>
                                    <IonCardContent>
                                        <p>{success}</p>
                                    </IonCardContent>
                                </IonCard>
                            )
                        }
                        <Formik
                            onSubmit={onSubmit}
                            initialValues={initialValue}
                            validationSchema={validationSchema}>
                            <Form className="form">
                                <div className="form--group">
                                    <label htmlFor="password">Nouveau mot de passe</label>
                                    <Field type="password" name="password" />
                                    <ErrorMessage name="password" component="label" className="form--error" />
                                </div>
                                <div className="form--group">
                                    <label htmlFor="confirm_password">Confirmer mot de passe</label>
                                    <Field type="password" name="confirm_password" />
                                    <ErrorMessage name="confirm_password" component="label" className="form--error" />
                                </div>
                                <div className="form--group">
                                    <button type="submit" className="btn btn--red">Modifier</button>
                                </div>
                                <div className="form--group">
                                    <Button color="white" title="Retour" className={null} onClick={() => {
                                        setSuccess(null);
                                        setError(null);
                                        window.history.back()
                                    }} />
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </IonContent>
                {
                    isLoading && (
                        <Loader type="full" />
                    )
                }
            </IonPage>
        </LoggedInRoute>
    );
}

export default EditPassword;