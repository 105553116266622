import { IonContent, IonPage } from "@ionic/react"
import NotFoundAnimation from "../components/NotFoundAnimation";
import TopBar from "../components/TopBar";

const NotFound: React.FC = () => {
    return (
        <IonPage>
            <TopBar title={null} />
            <IonContent>
                <div className="container page--404">
                    <NotFoundAnimation />
                </div>
            </IonContent>
        </IonPage>
    );
}

export default NotFound;