import { IonItem } from "@ionic/react"
import { Link } from "react-router-dom";
import { string } from "yup/lib/locale";

export interface CardProps {
    title: string,
    subTitle: string,
    type: 'user' | 'title',
    link: string
}


const Card = (props: CardProps) => {
    return (
        <div className="ion-background">
            <IonItem color="transparent">
                <Link to={props.link} className={`card card--${props.type}`}>
                    <div className="card--header">
                        <p className="card--title">{props.title}</p>
                    </div>
                    {
                        props.subTitle !== '' && (
                            <p className="card--content">{props.subTitle}</p>
                        )
                    }
                </Link>
            </IonItem>
        </div>
    );
}

export default Card;