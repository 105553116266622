import { IonButtons, IonHeader, IonMenuButton, IonTitle, IonToolbar } from "@ionic/react";
import { Link } from "react-router-dom";
import IconCircleUser from '../resources/icons/circle_user.svg';
import IconSearch from '../resources/icons/search.svg';
import IconHamburger from '../resources/icons/hamburger.svg';

interface topBarProps {
    title: string | null
}

const TopBar = (props: topBarProps) => {
    return (
        <IonHeader>
            <IonToolbar className="topbar" color="transparent">
                <IonButtons slot="start">
                    <IonMenuButton>
                        <img src={IconHamburger} />
                    </IonMenuButton>
                </IonButtons>
                {
                    props.title && (
                        <IonTitle>Blank</IonTitle>
                    )
                }
                <IonButtons slot="end" className="topbar--end">
                    <Link to='/search' className="topbar--icons">
                        <img src={IconSearch} />
                    </Link>
                    <Link to='/profil' className="topbar--icons">
                        <img src={IconCircleUser} />
                    </Link>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
}

export default TopBar;