import React, { useState, useEffect } from 'react';
import { IonContent, IonPage } from "@ionic/react";
import TopBar from "../components/TopBar";
import Logo from "../resources/logos/som_logo_s_white.svg";
import { useParams } from 'react-router-dom';
import { get_title, TitleInterface } from '../tools/content';
import Loader from '../components/loader';
import SpoilFeed from '../components/SpoilFeed';
import NotFoundAnimation from '../components/NotFoundAnimation';

const Title: React.FC = () => {

    let params: any = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [title, setTitle] = useState<TitleInterface | null>(null);

    useEffect(() => {
        setIsLoading(true);
        get_title(params.titleId).then((result: TitleInterface | null) => {
            if (result) {
                //Got a title
                setTitle(result);
                setIsLoading(false);
            } else {
                //Error
                setTitle(null);
                setIsLoading(false);
            }
        });
    }, [params]);

    return (
        <IonPage>
            <TopBar title={null} />
            <IonContent scrollY={false} fullscreen>
                <div className="page-title container">
                    {
                        isLoading ? (
                            <Loader type="full" />
                        ) : title === null ? (
                            <NotFoundAnimation />
                        ) : (
                            <>
                                <section className="page-title--header">
                                    <div className="page-title--grid">
                                        <div className="image no-image">
                                            <img src={Logo} />
                                        </div>
                                        <div className="details">
                                            <h2 className="title title--small">{title.title}</h2>
                                            <div className="page-title--sumary">
                                                <p className="text text--small">{title.description}</p>
                                            </div>
                                            <ul className="tag-list">
                                                {
                                                    title.genres.map((element, index) => (
                                                        <li key={index}>{element}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="page-title--spoil-count">
                                        <h4 className="text text--normal">Spoils</h4>
                                        <p className="text text--normal">{title.spoils}</p>
                                    </div>
                                </section>
                                <section className="page-title--body">
                                    <SpoilFeed type="title" username={null} titleId={params.titleId} />
                                </section>
                            </>
                        )
                    }
                </div>
            </IonContent>
        </IonPage>
    );
}

export default Title;