import { Redirect } from 'react-router';
import { useAuth } from '../context/AuthContext';

interface porpsComponent {
    children?: React.ReactNode
}

const LoggedOutRoute: React.FC = (props: porpsComponent) => {

    const { authState } = useAuth();

    if (authState.userToken !== null && authState.isLoading === false) {
        return (
            <Redirect to="/" />
        );
    }

    return (
        <>
            {props.children}
        </>
    );
}

export default LoggedOutRoute