import { IonGrid, IonRow } from "@ionic/react";
import IconHome from '../resources/icons/home.svg';
import IconAdd from '../resources/icons/add.svg';
import IconNotif from '../resources/icons/notification.svg';
import BottomBarItem from "./BottomBarItem";

const BottomBar: React.FC = () => {
    return (
        <nav className="bottom-bar" id="bottom-bar">
            <IonGrid>
                <IonRow>
                    <BottomBarItem link='/' icon={IconHome} />
                    <div className="add--btn">
                        <BottomBarItem link='/add-spoil' icon={IconAdd} />
                    </div>
                    <BottomBarItem link='/notifications' icon={IconNotif} />
                </IonRow>
            </IonGrid>
        </nav>
    )
}

export default BottomBar;