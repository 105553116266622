import axios from 'axios';
import { NOT_VALID_EMAIL } from './auth';
import { ADD_SPOIL, FIND_USER, GET_FOLLOWER, GET_FOLLOWING, GET_GENRES, GET_SPOIL, GET_TITLE, LAST_UPDATE, SEARCH, SEARCH_TITLE, SPOIL_FEED } from './serverEndpoints';

export const OK: string = "OK";
export const ERROR: string = "ERROR";
export const NOT_FOUND: string = "NOT_FOUND";
export const MISSING_VALUES: string = "MISSING_VALUES";
export const PAGE_NUMBER_NOT_VALID: string = "PAGE_NUMBER_NOT_VALID";
export const BANNED: string = 'BANNED';

//Genres
/**
 * Get all genres
 * @param page The current page
 * @returns A string code or the pagination object
 */
export const get_genres = async (page: number = 1): Promise<Object | string> => {
    if (page < 1 || page === null || page === undefined) {
        return PAGE_NUMBER_NOT_VALID;
    }
    //Send the request
    try {
        const result: any = await axios.get(`${GET_GENRES}?page=${page}&client_id=${process.env.REACT_APP_CLIENT}&client_secret=${process.env.REACT_APP_SECRET}`);
        return result.data;
    } catch (error) {
        return ERROR;
    }
}

//Spoils
/**
 * Function used to load spoils
 * @param page The current page
 * @param type The type (default, user, title)
 * @param date The date limite for the pagination
 * @param userToken The barer token (only used for the homepage when user is connected)
 * @param username The username (used in profil page)
 * @param titleId The title ID (used in title page)
 * @returns A string code or the pagination object
 */
export const spoil_feed = async (page: number = 1, type: "default" | "user" | "title" = "default", date: Date = new Date(), userToken: string | null = null, username: string | null, titleId: string | null = null): Promise<Object | string> => {
    if (page < 1) {
        return PAGE_NUMBER_NOT_VALID;
    }
    const formatedDate: string = date.getFullYear() + "-" +
        ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
        ("00" + date.getDate()).slice(-2) + " " +
        ("00" + date.getHours()).slice(-2) + ":" +
        ("00" + date.getMinutes()).slice(-2) + ":" +
        ("00" + date.getSeconds()).slice(-2);
    let url: string = `${SPOIL_FEED}?page=${page}&type=${type}&date=${formatedDate}&client_id=${process.env.REACT_APP_CLIENT}&client_secret=${process.env.REACT_APP_SECRET}`;
    let headers: any = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
    };

    switch (type) {
        case "user": {
            //Get spoils from user
            if (username === '') {
                return MISSING_VALUES;
            }
            url = `${url}&username=${username}`;
            break;
        }
        case "title": {
            //Get spoils from title
            if (titleId === '') {
                return MISSING_VALUES;
            }
            url = `${url}&title_id=${titleId}`;
            break;
        }
        default: {
            //Default
            if (userToken !== null) {
                headers = { ...headers, 'Authorization': `Bearer ${userToken}` };
            }
            break;
        }
    }

    //send the request
    try {
        const result: any = await axios.get(url, { headers });
        return result.data;
    } catch (error: any) {
        if (error.response.status === 404) {
            return NOT_FOUND;
        } else {
            return ERROR;
        }
    }
}

/**
 * Function used to get a specific spoil
 * @param spoilID The spoil ID
 * @returns a string code or the spoil
 */
export const get_spoil = async (spoilID: string): Promise<string | Object> => {
    if (spoilID === '') {
        return MISSING_VALUES;
    }

    const headers: any = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
    };

    try {
        const response: any = await axios.get(`${GET_SPOIL}/${spoilID}?client_id=${process.env.REACT_APP_CLIENT}&client_secret=${process.env.REACT_APP_SECRET}`, { headers: { headers } });
        return response.data;
    } catch (error: any) {
        switch (error.response.status) {
            case 404: {
                return NOT_FOUND;
            }
            default: {
                return ERROR;
            }
        }
    }
}

/**
 * Function used to send spoils
 * @param token The barer token
 * @param userID The sender ID
 * @param titleID The Title ID
 * @param content The spoil content
 * @returns A string code
 */
export const add_spoil = async (token: string = '', userID: string = '', titleID: string = '', content: string = ''): Promise<string> => {
    console.log(`TOKEN : ${token}`);
    console.log(`UserID : ${userID}`);
    console.log(`titleID : ${titleID}`);
    console.log(`content : ${content}`);
    
    if(token === '' || userID === '' || titleID === '' || content === '') {
        return MISSING_VALUES;
    }

    const headers: any = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    };

    const formData = new FormData();
    formData.append('user_id', userID);
    formData.append('title_id', titleID);
    formData.append('content', content);

    try {
        const response: any = await axios.post(ADD_SPOIL, formData, { headers });
        if (response.data) {
            return OK;
        } else {
            return ERROR
        }
    } catch (error: any) {
        switch (error.response.status) {
            case 401: {
                if (error.response.data.user_id === 'Email is not verified') {
                    return NOT_VALID_EMAIL;
                } else {
                    return BANNED;
                }
            }
            default: {
                return ERROR;
            }
        }
    }
}

//Titles
interface TitleVarientInterface {
    id: string,
    title_id: string,
    title: string,
    startDate: string,
    endDate: string,
    visible: number,
    created_at: string,
    updated_at: string
}

export interface SearchTitleInterface {
    id: string,
    title: string,
    description: string,
    category: string,
    varients: Array<TitleVarientInterface>,

}

/**
 * Function used to serach title from a query
 * @param query The query
 * @returns Array of titles
 */
export const search_title = async (query: string = ''): Promise<Array<SearchTitleInterface>> => {
    if (query === '') {
        return [];
    }

    const headers: any = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
    };

    try {
        const response: any = await axios.get(`${SEARCH_TITLE}?query=${query}&client_id=${process.env.REACT_APP_CLIENT}&client_secret=${process.env.REACT_APP_SECRET}`, { headers });
        return response.data;
    } catch {
        return [];
    }
}

export interface UpdatesTitle {
    id: string,
    title: string,
    image: null | string
};

/**
 * Function used to get the last updates on the title
 * @return array<updateTitles>
 */
export const get_last_updates = async (): Promise<Array<UpdatesTitle>> => {
    const headers: any = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
    };

    try {
        const response: any = await axios.get(`${LAST_UPDATE}?client_id=${process.env.REACT_APP_CLIENT}&client_secret=${process.env.REACT_APP_SECRET}`, { headers });
        return response.data;
    } catch (error) {
        return [];
    }
}

export interface TitleInterface {
    title: string,
    varients: number,
    spoils: number,
    description: string,
    genres: Array<string>,
    image: null | string
}

/**
 * Function used to get the details of a title
 * @param title_id The title ID
 * @returns The title or null
 */
export const get_title = async (title_id: string): Promise<null | TitleInterface> => {
    const headers: any = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
    };

    try {
        const response: any = await axios.get(`${GET_TITLE}/${title_id}?client_id=${process.env.REACT_APP_CLIENT}&client_secret=${process.env.REACT_APP_SECRET}`, { headers });
        return response.data;
    } catch (error) {
        return null;
    }
}


//User
export interface UserInterface {
    id: string,
    username: string,
    nb_spoil: number,
    following: number,
    followers: number
};

/**
 * Fucntion used to get a specific user
 * @param username The username
 * @returns The user object or a string error code
 */
export const get_user = async (username: string): Promise<string | UserInterface> => {
    if (username === '') {
        return MISSING_VALUES;
    }

    const headers: any = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
    };

    try {
        const response: any = await axios.get(`${FIND_USER}/${username}?client_id=${process.env.REACT_APP_CLIENT}&client_secret=${process.env.REACT_APP_SECRET}`, { headers });
        return response.data;
    } catch (error: any) {
        switch (error.response.status) {
            case 404: {
                return NOT_FOUND;
            }
            default: {
                return ERROR;
            }
        }
    }
}

/**
 * Function used to return the followers of a user
 * @param userID The user ID
 * @returns A string code error or paginate object
 */
export const get_followers = async (userID: string, page: number = 1): Promise<any> => {
    if (userID === '') {
        return MISSING_VALUES;
    }

    const headers: any = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
    };

    try {
        const response: any = await axios.get(`${GET_FOLLOWER}/${userID}?page=${page}&client_id=${process.env.REACT_APP_CLIENT}&client_secret=${process.env.REACT_APP_SECRET}`, { headers });
        return response.data;
    } catch (error: any) {
        switch (error.response.status) {
            case 404: {
                return NOT_FOUND;
            }
            default: {
                return ERROR;
            }
        }
    }
}

/**
 * Function used to return the following of a user
 * @param userID The user ID
 * @returns A string code error or paginate object
 */
export const get_following = async (userID: string, page: number = 1): Promise<any> => {
    if (userID === '') {
        return MISSING_VALUES;
    }

    const headers: any = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
    };

    try {
        const response: any = await axios.get(`${GET_FOLLOWING}/${userID}?page=${page}&client_id=${process.env.REACT_APP_CLIENT}&client_secret=${process.env.REACT_APP_SECRET}`, { headers });
        return response.data;
    } catch (error: any) {
        switch (error.response.status) {
            case 404: {
                return NOT_FOUND;
            }
            default: {
                return ERROR;
            }
        }
    }
}

export interface SearchInterface {
    type: string,
    name: string,
    ref: string
}

/**
 * Function used to search user and titles
 * @param query The search query
 * @returns The search results
 */
export const search = async (query: string): Promise<Array<SearchInterface>> => {
    const headers: any = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
    };

    try {
        const response: any = await axios.get(`${SEARCH}?query=${query}&client_id=${process.env.REACT_APP_CLIENT}&client_secret=${process.env.REACT_APP_SECRET}`, { headers });
        return response.data;
    } catch (error) {
        return [];
    }
}