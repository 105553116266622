import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonContent, IonPage, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import Login from './pages/Login';
import Profil from './pages/Profil';
import Register from './pages/Register';
import { RESTORE_TOKEN, useAuth } from './context/AuthContext';
import { getCurrentUserDetail } from './tools/auth';
import ForgotPassword from './pages/ForgotPassword';
import BottomBar from './components/BottomBar';
import Nav from './components/Nav';
import Search from './pages/Search';
import Title from './pages/Title';
import SpoilWriter from './pages/SpoilWriter';
import Notification from './pages/Notification';
import FeedSettings from './pages/FeedSettings';
import UserSettings from './pages/UserSettings';
import UpdateUser from './pages/UpdateUser';
import NotFound from './pages/NotFound';
import Logout from './pages/Logout';
import Loader from './components/loader';
import EditUsername from './pages/EditUsername';
import EditPassword from './pages/EditPassword';
import DeleteUser from './pages/DeleteUser';
import EditEmail from './pages/EditEmail';
import Cgv from './pages/Cgv';

const SpoilOMatic: React.FC = () => {

  const { authState, authDispatch } = useAuth();

  useEffect(() => {
    if (localStorage.getItem('som') !== null) {
      const token: any = localStorage.getItem('som');
      getCurrentUserDetail(token).then((response: any) => {
        if (response === null) {
          //Token not valid
          localStorage.removeItem('som');
          authDispatch({
            type: RESTORE_TOKEN,
            token: null,
            user_id: null,
            username: null,
            email: null,
            nb_spoil: null
          });
        } else {
          //Tokne valid
          authDispatch({
            type: RESTORE_TOKEN,
            token: token,
            user_id: response.id,
            username: response.username,
            email: response.email,
            nb_spoil: response.nb_spoil
          });
        }
      });
    } else {
      authDispatch({
        type: RESTORE_TOKEN,
        token: null,
        user_id: null,
        username: null,
        email: null,
        nb_spoil: null
      });
    }
  }, []);

  //Wait for app loading
  if (authState.isLoading === true) {
    return (
      <IonApp>
        <IonPage>
          <IonContent>
            <Loader type="full" />
          </IonContent>
        </IonPage>
      </IonApp>
    )
  } else {
    return (
      <IonApp color="transparent">
        <IonReactRouter>
          <Nav />
          <IonRouterOutlet id="main-view" color="tranparent">
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/profil">
              <Profil />
            </Route>
            <Route exact path="/profil/:userId">
              <Profil />
            </Route>
            <Route exact path="/search">
              <Search />
            </Route>
            <Route exact path="/title/:titleId">
              <Title />
            </Route>
            <Route exact path="/add-spoil">
              <SpoilWriter />
            </Route>
            <Route exact path="/notifications">
              <Notification />
            </Route>
            <Route exact path="/settings/feed">
              <FeedSettings />
            </Route>
            <Route exact path="/settings/user">
              <UserSettings />
            </Route>
            <Route exact path="/settings/user/username">
              <EditUsername />
            </Route>
            <Route exact path="/settings/user/password">
              <EditPassword />
            </Route>
            <Route exact path="/settings/user/email">
              <EditEmail />
            </Route>
            <Route exact path="/settings/user/delete">
              <DeleteUser />
            </Route>
            <Route exact path="/settings/user/update">
              <UpdateUser />
            </Route>
            <Route exact path="/logout">
              <Logout />
            </Route>
            <Route exact path="/cgv">
              <Cgv />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </IonRouterOutlet>
          <BottomBar />
        </IonReactRouter>
      </IonApp>
    )
  }
}

export default SpoilOMatic;
