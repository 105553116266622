import { useState, useEffect } from 'react';
import { useAuth } from "../context/AuthContext";
import { ERROR, isFollowing, MISSING_VALUES, startFollow, stopFollow } from '../tools/auth';
import Button from './Button';

interface FollowButtonProps {
    userID: string
}

const FollowButton = (props: FollowButtonProps) => {

    const { authState } = useAuth();
    const [userIsFollowing, setUserIsFollowing] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);


    useEffect(() => {
        setError(false);
        setIsLoading(true);

        isFollowing(authState.userToken, props.userID).then((response: string | boolean) => {
            if (response === ERROR) {
                setError(true);
            } else if (response === MISSING_VALUES) {
                setError(true);
            } else if (response === true) {
                setUserIsFollowing(true);
            } else {
                setUserIsFollowing(false);
            }

            setIsLoading(false);
        });
    }, [props.userID, authState]);

    const handleFollow = () => {
        if (userIsFollowing) {
            //Unfollow
            stopFollow(authState.userToken, props.userID).then((response: string | boolean) => {
                if (response === true) {
                    setUserIsFollowing(false);
                }
            });
        } else {
            //Follow
            startFollow(authState.userToken, props.userID).then((response: string | boolean) => {
                if (response === true) {
                    setUserIsFollowing(true);
                }
            });
        }
    }

    return (
        <>
            {
                authState.user_id !== props.userID && props.userID !== '' && !error ? isLoading ? (
                    <button>Chargement ...</button>
                ) : (
                    <Button className={'follow-btn'} title={userIsFollowing ? 'Abonné' : 'Suivre'} color={userIsFollowing ? 'white' : 'red'} onClick={handleFollow} />
                ) : (
                    <></>
                )
            }
        </>
    );
}

export default FollowButton;