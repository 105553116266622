import React, { useEffect } from 'react';
import Loader from '../components/loader';
import { useAuth } from '../context/AuthContext';
import { logout } from '../tools/auth';
import LoggedInRoute from "../tools/LoggedInRoute"

const Logout: React.FC = () => {

    const { authDispatch, authState } = useAuth();

    useEffect(() => {
        logout(authDispatch, authState.userToken);
    }, []);

    return (
        <LoggedInRoute>
            <Loader type="full" />
        </LoggedInRoute>
    );
}

export default Logout;