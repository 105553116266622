import React, { useState } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonContent, IonPage } from "@ionic/react"
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import Button from "../components/Button";
import TopBar from "../components/TopBar"
import LoggedInRoute from "../tools/LoggedInRoute"
import Loader from '../components/loader';
import { OK, sendCode, updateUser, validateEmail } from '../tools/auth';
import { useAuth } from '../context/AuthContext';

const EditEmail: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [checkEmail, setCheckEmail] = useState<boolean>(false);
    const { authState } = useAuth();

    const onSubmit = (value: any) => {
        setIsLoading(true);
        setError(null);
        setSuccess(null);
        updateUser(authState.user_id, authState.userToken, null, value.email, null, null).then((result) => {
            if (result === OK) {
                setCheckEmail(true);
                sendCode(value.email)
            } else {
                setError('Veuillez essayer une autre adresse e-mail.');
            }
            setIsLoading(false);
        })
    }

    const onSubmitValidate = (value: any) => {
        setIsLoading(true);
        setError(null);
        validateEmail(authState.user_id, value.code).then((result: any) => {
            if (result === OK) {
                setSuccess('Votre adresse e-mail a bien été mise à jour.');
            } else {
                setError('Une erreur est survenue. Veuillez vérifier le code saisi.')
            }
            setIsLoading(false);
        });
    }

    const validationSchema = Yup.object({
        email: Yup.string().required('Obligatoire').email('Adresse e-mail non valide')
    });

    const validationSchemaValidation = Yup.object({
        code: Yup.string().required('Obligatoire')
    })

    const initialValue = {
        email: ''
    };

    const initialValueValidation = {
        code: ''
    };

    return (
        <LoggedInRoute>
            <IonPage>
                <TopBar title={null} />
                <IonContent>
                    <div className="container page--edit-username scroller">
                        <h2 className="title">Modifiez votre adresse e-mail</h2>
                        {
                            error && (
                                <IonCard color="danger">
                                    <IonCardHeader className="title">Oups</IonCardHeader>
                                    <IonCardContent>
                                        <p>{error}</p>
                                    </IonCardContent>
                                </IonCard>
                            )
                        }
                        {
                            success ? (
                                <IonCard color="success">
                                    <IonCardHeader className="title title--white">C'est tout bon !</IonCardHeader>
                                    <IonCardContent>
                                        <p>{success}</p>
                                        <Button color="white" title="Retour" className={null} onClick={() => {
                                            setError(null);
                                            setCheckEmail(false);
                                            setSuccess(null);
                                            window.history.back()
                                        }} />
                                    </IonCardContent>
                                </IonCard>
                            ) : checkEmail ? (
                                <>
                                    <p className="text">Veuillez valider votre nouvelle adresse e-mail en indiquant le code envoyé à votre nouvelle adresse.<br />Pensez à vérifier vos spams !</p>
                                    <Formik
                                        onSubmit={onSubmitValidate}
                                        initialValues={initialValueValidation}
                                        validationSchema={validationSchemaValidation}>
                                        <Form className="form">
                                            <div className="form--group">
                                                <label htmlFor="code">Code de vérification</label>
                                                <Field type="text" name="code" />
                                                <ErrorMessage name="code" className="form--error" component="label" />
                                            </div>
                                            <div className="form--group">
                                                <button type="submit" className="btn btn--red">Confirmer</button>
                                            </div>
                                        </Form>
                                    </Formik>
                                </>
                            ) : (
                                <Formik
                                    onSubmit={onSubmit}
                                    initialValues={initialValue}
                                    validationSchema={validationSchema}>
                                    <Form className="form">
                                        <div className="form--group">
                                            <label>Nouvelle adresse e-mail</label>
                                            <Field type="email" name="email" />
                                            <ErrorMessage name="email" component="label" className="form--error" />
                                        </div>
                                        <div className="form--group">
                                            <button type="submit" className="btn btn--red">Modifier</button>
                                        </div>
                                        <div className="form--group">
                                            <Button color="white" title="Retour" className={null} onClick={() => {
                                                window.history.back()
                                            }} />
                                        </div>
                                    </Form>
                                </Formik>
                            )
                        }
                    </div>
                </IonContent>
                {
                    isLoading && (
                        <Loader type="full" />
                    )
                }
            </IonPage>
        </LoggedInRoute>
    );
}

export default EditEmail;