import React, { useRef, useState } from 'react';
import { IonBadge, IonContent, IonPage } from "@ionic/react";
import CardList from "../components/CardList";
import TopBar from "../components/TopBar";
import SearchIcon from '../resources/icons/search.svg';
import { CardProps } from '../components/Card';
import { search, SearchInterface } from '../tools/content';

const Search: React.FC = () => {

    const searchRef = useRef<any>();
    const [data, setData] = useState<Array<CardProps>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleSearch = (event: any) => {
        event.preventDefault();
        setError(null);
        setIsLoading(true);
        search(searchRef.current.value).then((response: Array<SearchInterface>) => {
            if (response.length > 0) {
                const newData: Array<CardProps> = [];

                response.forEach(element => {
                    newData.push({
                        title: element.name,
                        subTitle: element.type,
                        type: element.type === 'Utilisateur' ? 'user' : 'title',
                        link: element.type === 'Utilisateur' ? `/profil/${element.ref}` : `/title/${element.ref}`
                    });
                });

                setData(newData);
            } else {
                //No result
                setError('Aucun résultat');
            }
            setIsLoading(false);
        });
    }

    return (
        <IonPage>
            <TopBar title={null} />
            <IonContent fullscreen>
                <div className="page--search container">
                    <form className="form search-bar" onSubmit={handleSearch}>
                        <div className="form--group">
                            <label htmlFor="query">Rechercher des titres ou des utilisateurs</label>
                            <input type="text" id="query" name="query" ref={searchRef} />
                            <button type="submit" className="btn btn--red"><img src={SearchIcon} alt="Rechercher" /></button>
                        </div>
                    </form>
                    {
                        error ? (
                            <IonBadge color="primary">
                                {error}
                            </IonBadge>
                        ) : (
                            <CardList loadMore={() => { }} disabled={true} data={data} isLoading={isLoading} />
                        )
                    }
                </div>
            </IonContent>
        </IonPage>
    );
}

export default Search;