import { IonContent, IonPage } from "@ionic/react"
import Button from "../components/Button"
import TopBar from "../components/TopBar"

const Cgv: React.FC = () => {
    return (
        <IonPage>
            <TopBar title={null} />
            <IonContent>
                <div className="container page--cgv">
                    <Button className={null} title="Retour" color="white" onClick={() => { window.history.back() }} />
                    <h2 className="title title--large">Mentions légales Spoil&nbsp;O&nbsp;Matic</h2>
                    <section className="cgv-editor">
                        <p className="title title--small">Éditeur</p>
                        <p className="text text--normal">Thomas Borgognon<br />contact@borgognon.dev</p>
                    </section>
                    <section className="cgv-hosting">
                        <p className="title title--small">Hébergeur</p>
                        <p className="text text--normal">Infomaniak Network SA<br />Rue Eugène-Marziano 25, 1227&nbsp;Les&nbsp;Acacias (Suisse)<br />CHE-103.167.648<br /><a href="https://www.infomanaik.com" target="_blank">https://www.infomanaik.com</a></p>
                    </section>
                    <h2 className="title title--small">Utilisations des données personnelles collectées</h2>
                    <section className="cgv-register">
                        <p className="title title--small">Inscription</p>
                        <p className="text text--normal">Lors de votre inscription, les données saisies dans le formulaire sont collectées. Votre adresse e-mail est utilisée à des fins de modération pour éviter les excès sur la plateforme.</p>
                    </section>
                    <section className="cgv-login">
                        <p className="title title--small">Connexion</p>
                        <p className="text text--normal">Lors de votre connexion, la date et l’heure sont enregistrées pour révoquer votre connexion au bout de 6 mois. Un jeton est aussi sauvegardé dans votre navigateur pour vous éviter de ressaisir  vos information de connexion à chaque utilisation.</p>
                    </section>
                    <section className="cgv-cookies">
                        <p className="title title--small">Cookies</p>
                        <p className="text text--normal">Les cookies sont uniquement utilisés à des fin fonctionnelles et non marketing. Ils permettent de vous garder connecté·e à la plateforme.</p>
                    </section>
                    <section className="cgv-stats">
                        <p className="title title--small">Statistiques et mesures d’audience</p>
                        <p className="text text--normal">Pour le moment, il n’y a aucun système de mesures de statistiques et d’audience.</p>
                    </section>
                    <h2 className="title title--small">Utilisation et transmission de vos données personnels</h2>
                    <section className="cgv-stock">
                        <p className="title title--small">Durée de stockage de vos données</p>
                        <p className="text text--normal">Si vous envoyez un Spoil, les détails de votre compte sont stockés indéfiniment. En ce qui concerne vos connexions, celles-ci sont supprimées lorsque les jetons expirent au bout de 3 ans.</p>
                    </section>
                    <section className="cgv-rights">
                        <p className="title title--small">Les droit que vous avez sur vos données</p>
                        <p className="text text--normal">Si vous avez un compte, vous pouvez demander à obtenir toutes les données que nos possédons à votre sujet, incluant celle que vous nous avez fournies. Vous pouvez également les supprimer en supprimant votre compte.</p>
                    </section>
                    <section className="cgv-transfert">
                        <p className="title title--small">Transmission de vos données personnelles</p>
                        <p className="text text--normal">Pour le moment aucune donnée n’est sujette à des transferts à des entreprises tierces.</p>
                    </section>
                    <section className="cgv-contact">
                        <p className="title title--small">Information de contact</p>
                        <p className="text text--normal">Thomas Borgognon<br />contact@spoil-o-matic.com</p>
                    </section>
                    <section className="cgv-contact">
                        <p className="title title--small">Information de contact</p>
                        <p className="text text--normal">Thomas Borgognon<br />contact@spoil-o-matic.com</p>
                    </section>
                    <section className="cgv-comunity">
                        <p className="title title--small">Règlement sur la publications des spoils</p>
                        <p className="text text--normal">Lors de la publication d’un Spoil, vous devez respecter certaines règles. Dans le cas où vous enfreignez ces règles, vous exposez votre compte à un bloquage temporaire ou définitif.</p>
                        <ul>
                            <li>Votre Spoil peut être véridique ou faux.</li>
                            <li>Votre Spoil doit être en rapport avec le titre et la saison sélectionné(e).</li>
                            <li>Toute propagande est strictement interdite.</li>
                            <li>Toute discrimination est strictement interdite.</li>
                            <li>Les insultes sont interdites.</li>
                            <li>Il est interdit de citer un autre utilisateur dans un Spoil.</li>
                            <li>Il est interdit d’harceler ou d’attaquer une personne morale ou une entité.</li>
                        </ul>
                    </section>
                    <section className="cgv-supp">
                        <p className="title title--small">Informations supplémentaires</p>
                        <p className="text text--normal">Ces conditions peuvent être modifiées à tout moment. L’utilisateur prend donc la responsabilité de les vérifier régulièrement.</p>
                    </section>
                    <Button className={null} title="Retour" color="white" onClick={() => { window.history.back() }} />
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Cgv