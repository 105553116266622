import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage } from "@ionic/react";
import { useState, useEffect } from 'react';
import { CODE_EXPIRED, CODE_INVALID, EMAIL_ALREADY_TAKEN, login, OK, registerUser, updateUser, USERNAME_ALREADY_TAKEN, validateEmail } from "../tools/auth";
import LoggedOutRoute from "../tools/LoggedOutRoute";
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { get_genres } from "../tools/content";
import { SIGN_IN, useAuth } from "../context/AuthContext";
import TopBar from "../components/TopBar";
import ProgressBar from "../components/ProgressBar";
import Button from "../components/Button";
import Loader from "../components/loader";
import Camera from '../resources/images/lubitel-camera.jpg';
import BackgroundImage from "../components/BackgroundImage";
import { Link } from "react-router-dom";

const Register: React.FC = () => {

    const [user, setUser] = useState<any>(null);
    const [code, setCode] = useState<string | null>(null);
    const [hasSetTopics, setHasSetTopics] = useState<boolean | null>(false);
    const [error, setError] = useState<string | null>(null);
    const [authToken, setAuthToken] = useState<any>(null);
    const [topics, setTopics] = useState<Array<any>>([]);
    const [topicsPage, setTopicsPage] = useState<number>(1);
    const [progress, setProgress] = useState<number>(33);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { authDispatch } = useAuth();

    const validationUserSchema = Yup.object({
        username: Yup.string().required('Obligatoire').min(3, "Doit contenir au minum 3 caractères").max(20, "Doit contenir au maximum 20 caractères").matches(/^[a-zA-Z0-9._]{3,20}$/g, "Peut contenir uniquement des lettres, chiffres, tirets du bas et des points."),
        email: Yup.string().required('Obligatoire').email('Doit être une adresse e-mail valide'),
        password: Yup.string().required('Obligatoire').min(8, 'Doit contenir au minimum 8 caractères').matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,255}$/g, 'Dois contenir au minimum 1 majuscule, 1 minuscule et 1 chiffre'),
        password_confirmation: Yup.string().required('Obligatoire').oneOf([Yup.ref('password'), null], 'Les mot de passes ne correspondent pas'),
        cgv: Yup.bool().oneOf([true], 'Obligatoire')
    });

    const validationTopicSchema = Yup.object({
        topics: Yup.array().required('Obligatoire').min(1, 'Veuillez choisir au moins un genre')
    });

    const validationCodeSchema = Yup.object({
        code: Yup.number().required('Obligatoire')
    })

    const initialUserValues = {
        username: '',
        email: '',
        password: '',
        password_confirmation: '',
        cgv: false
    };

    const initialTopicValues = {
        topics: []
    };

    const initialCodeValues = {
        code: ''
    };

    //Handle registration
    const handleRegistration = (data: any) => {
        setIsLoading(true);
        registerUser(data.username, data.password, data.password_confirmation, data.email).then((result: any) => {
            if (result.message !== OK) {
                //Server return an error
                if (result.message === USERNAME_ALREADY_TAKEN) {
                    setError("Ce nom d'utilisateur est déjà utilisé");
                } else if (result.message === EMAIL_ALREADY_TAKEN) {
                    setError("Cette adresse e-mail est déjà utilisé");
                } else {
                    setError("Une erreur est survenue");
                }
            } else {
                //User is now registred
                login(data.username, data.password).then((response) => {
                    setProgress(66);
                    setError(null);
                    setAuthToken(response);
                    setUser(result.user);
                });
            }
            setIsLoading(false);
        });
    }

    //Handle code verification
    const handleEmailCheck = (data: any) => {
        setIsLoading(true);
        validateEmail(authToken.user.id, data.code).then((response) => {
            switch (response) {
                case CODE_INVALID: {
                    setError('Le code saisi est invalide');
                    break;
                }
                case CODE_EXPIRED: {
                    setError('Le code à expiré');
                    break;
                }
                case OK: {
                    //Login
                    localStorage.setItem('som', authToken.access_token);
                    authDispatch({
                        type: SIGN_IN,
                        token: authToken.access_token,
                        user_id: authToken.user.id,
                        username: authToken.user.username,
                        email: authToken.user.email,
                        nb_spoil: authToken.user.nb_spoil
                    });
                    break;
                }
                default: {
                    setError('Une erreur est survenue');
                    break;
                }
            }
            setIsLoading(false);
        });
    }

    //Handle topics
    const handleTopics = (data: any) => {
        setIsLoading(true);
        updateUser(authToken.user.id, authToken.access_token, null, null, null, data.topics).then((response) => {
            if (response === OK) {
                setError(null);
                setHasSetTopics(true);
                setProgress(100);
            } else {
                setError('Une erreur est survenue');
            }
            setIsLoading(false);
        });
    }

    //Set up
    useEffect(() => {
        get_genres(topicsPage).then((response: any) => {
            setTopics(response.data);
        });
    }, []);

    return (
        <LoggedOutRoute>
            <IonPage>
                <TopBar title={null} />
                <IonContent fullscreen scrollY={false}>
                    <div className="page--register container">
                        <ProgressBar progress={progress} />
                        {
                            error !== null && (
                                <IonCard color="danger">
                                    <IonCardHeader>
                                        <IonCardTitle>Oups</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <p>{error}</p>
                                    </IonCardContent>
                                </IonCard>
                            )
                        }
                        {
                            user === null ? (
                                <>
                                    <h1 className="title">Étape 1</h1>
                                    <Formik
                                        initialValues={initialUserValues}
                                        validationSchema={validationUserSchema}
                                        onSubmit={handleRegistration}>
                                        <Form className="form">
                                            <div className="container--form">
                                                <div className="form--group">
                                                    <label htmlFor="username">Nom d'utilisateur</label>
                                                    <Field name="username" type="text" />
                                                    <ErrorMessage name="username" component="label" className="form--error" />
                                                </div>
                                                <div className="form--group">
                                                    <label htmlFor="email">Email</label>
                                                    <Field name="email" type="email" />
                                                    <ErrorMessage name="email" component="label" className="form--error" />
                                                </div>
                                                <div className="form--group">
                                                    <label htmlFor="password">Mot de passe</label>
                                                    <Field name="password" type="password" />
                                                    <ErrorMessage name="password" component="label" className="form--error" />
                                                </div>
                                                <div className="form--group">
                                                    <label htmlFor="password_confirmation">Confirmer mot de passe</label>
                                                    <Field name="password_confirmation" type="password" />
                                                    <ErrorMessage name="password_confirmation" component="label" className="form--error" />
                                                </div>
                                                <div className="form--group">
                                                    <label className="checkbox" htmlFor="cgv">
                                                        <Field type="checkbox" name="cgv" />
                                                        <span className="icon" />
                                                        <div className="checkbox--label">
                                                            <span>J'accepte <Link to="/cgv">la politique de confidentialité et d'utilisation du Spoil&nbsp;O&nbsp;Matic</Link></span>
                                                        </div>
                                                    </label>
                                                    <ErrorMessage name="cgv" component="label" className="form--error" />
                                                </div>
                                                <div className="form--group">
                                                    <div className="form--group btn btn--red">
                                                        <input type="submit" value="Suivant" />
                                                    </div>
                                                    <div className="form--group">
                                                        <Button className={null} title="Retour" color="white" onClick={() => { window.history.back() }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </>
                            ) : hasSetTopics === false ? (
                                <>
                                    <h1 className="title">Étape 2</h1>
                                    <p>Quel sont tes genres préférés ?</p>
                                    <Formik
                                        initialValues={initialTopicValues}
                                        onSubmit={handleTopics}
                                        validationSchema={validationTopicSchema}>
                                        <Form className="form form--full-height">
                                            <div className="container--form">
                                                <div className="form--grid">
                                                    {
                                                        topics.map((topic) => (
                                                            <label key={topic.id} className="grid--item">
                                                                <Field type="checkbox" className="item--checkbox" name="topics" value={topic.id} />
                                                                <div className="item--label">
                                                                    <span>{topic.name}</span>
                                                                </div>
                                                            </label>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <ErrorMessage name="topics" component="label" className="form--error" />
                                            <div className="container--form--btn">
                                                <div className="form--group btn btn--red">
                                                    <input type="submit" value="Continuer" />
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </>
                            ) : code === null ? (
                                <>
                                    <h1 className="title">Étape 3</h1>
                                    <p>Nous t’avons envoyé un code dans ta boite e-mail afin de confirmer la création de ton compte.<br />Pense à vérifier tes spams.</p>
                                    <Formik
                                        initialValues={initialCodeValues}
                                        validationSchema={validationCodeSchema}
                                        onSubmit={handleEmailCheck}>
                                        <Form className="form form--full-height">
                                            <div className="container--form">
                                                <div className="form--group">
                                                    <label htmlFor="code">Code de vérification</label>
                                                    <Field name="code" type="text" />
                                                    <ErrorMessage name="code" component="label" className="form--error" />
                                                </div>
                                            </div>
                                            <div className="container--form--btn">
                                                <div className="form--group btn btn--red">
                                                    <input type="submit" value="Terminer" />
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </>
                            ) : (
                                <>
                                    <h1 className="title">Bienvenue {user.username}</h1>
                                </>
                            )
                        }
                    </div>
                </IonContent>
                {
                    isLoading && (
                        <Loader type="full" />
                    )
                }
                <BackgroundImage image={Camera} />
            </IonPage>
        </LoggedOutRoute>
    )
}

export default Register;