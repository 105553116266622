import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage } from "@ionic/react";
import { useState } from 'react';
import { login } from "../tools/auth";
import { SIGN_IN, useAuth } from "../context/AuthContext";
import LoggedOutRoute from "../tools/LoggedOutRoute";
import { Link } from "react-router-dom";
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import TopBar from "../components/TopBar";
import Loader from "../components/loader";
import Arrow from '../resources/icons/arrow_left.svg';
import BackgroundImage from "../components/BackgroundImage";
import Smoking from '../resources/images/smoking-man.jpg';

const Login: React.FC = () => {

    const validationSchema = Yup.object({
        username: Yup.string().required('Obligatoire'),
        password: Yup.string().required('Obligatoire')
    });

    const initialValues = {
        username: '',
        password: ''
    };

    const { authDispatch } = useAuth();
    const [error, setError] = useState<null | string>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSubmit = (data: any) => {
        const username: string = data.username;
        const password: string = data.password;

        setIsLoading(true);
        login(username, password).then((response: any): void => {
            if (response !== null) {
                //User is logged
                localStorage.setItem('som', response.access_token);
                authDispatch({
                    type: SIGN_IN,
                    token: response.access_token,
                    user_id: response.user.id,
                    username: response.user.username,
                    email: response.user.email,
                    nb_spoil: response.user.nb_spoil
                });
            } else {
                setError('Veuillez vérifier vos identifiants');
            }
            setIsLoading(false);
        });
    };

    return (
        <LoggedOutRoute>
            <IonPage>
                <TopBar title={null} />
                <IonContent fullscreen scrollY={false}>
                    <div className="page--login container">
                        <h1 className="title">Se connecter</h1>
                        {
                            error !== null && (
                                <IonCard color="danger">
                                    <IonCardHeader>
                                        <IonCardTitle>Oups</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <p>{error}</p>
                                    </IonCardContent>
                                </IonCard>
                            )
                        }
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}>
                            <Form className="form">
                                <div className="form--group">
                                    <label htmlFor="username">Username / email</label>
                                    <Field type="text" name="username" />
                                    <ErrorMessage name="username" component="label" className="form--error" />
                                </div>
                                <div className="form--group">
                                    <label htmlFor="username">Mot de passe</label>
                                    <Field type="password" name="password" />
                                    <ErrorMessage name="password" component="label" className="form--error" />
                                </div>
                                <div className="flex--link">
                                    <Link to="forgot-password">Mot de passe oublié</Link><br />
                                    <Link to="/register">Je n'ai pas de compte</Link>
                                </div>
                                <div className="form--group submit">
                                    <button type="submit" className="btn btn--red btn-submit"><img src={Arrow} alt="Se connecter" /></button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </IonContent >
                {
                    isLoading && (
                        <Loader type="full" />
                    )
                }
                <BackgroundImage image={Smoking} />
            </IonPage>
        </LoggedOutRoute>
    );
};

export default Login;
