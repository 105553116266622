import React, { useState } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonContent, IonPage } from "@ionic/react"
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import Button from "../components/Button";
import TopBar from "../components/TopBar"
import LoggedInRoute from "../tools/LoggedInRoute"
import Loader from '../components/loader';
import { OK, updateUser } from '../tools/auth';
import { useAuth } from '../context/AuthContext';

const EditUsername: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const { authState } = useAuth();

    const onSubmit = (value: any) => {
        setIsLoading(true);
        setError(null);
        setSuccess(null);
        updateUser(authState.user_id, authState.userToken, value.username, null, null, null).then((result) => {
            if (result === OK) {
                setSuccess('Votre nom d\'utilisateur à bien été modifié.');
            } else {
                setError('Veuillez essayer un autre nom d\'utilisateur.');
            }
            setIsLoading(false);
        })
    }

    const validationSchema = Yup.object({
        username: Yup.string().required('Obligatoire').min(3, "Doit contenir au minum 3 caractères").max(20, "Doit contenir au maximum 20 caractères").matches(/^[a-zA-Z0-9._]{3,20}$/g, "Peut contenir uniquement des lettres, chiffres, tirets du bas et des points.")
    });

    const initialValue = {
        username: ''
    };

    return (
        <LoggedInRoute>
            <IonPage>
                <TopBar title={null} />
                <IonContent>
                    <div className="container page--edit-username scroller">
                        <h2 className="title">Modifiez votre nom d'utilisateur</h2>
                        {
                            error && (
                                <IonCard color="danger">
                                    <IonCardHeader className="title">Oups</IonCardHeader>
                                    <IonCardContent>
                                        <p>{error}</p>
                                    </IonCardContent>
                                </IonCard>
                            )
                        }
                        {
                            success && (
                                <IonCard color="success">
                                    <IonCardHeader className="title title--white">C'est tout bon !</IonCardHeader>
                                    <IonCardContent>
                                        <p>{success}</p>
                                    </IonCardContent>
                                </IonCard>
                            )
                        }
                        <Formik
                            onSubmit={onSubmit}
                            initialValues={initialValue}
                            validationSchema={validationSchema}>
                            <Form className="form">
                                <div className="form--group">
                                    <label htmlFor="username">Nouveau nom d'utilisateur</label>
                                    <Field type="text" name="username" />
                                    <ErrorMessage name="username" component="label" className="form--error" />
                                </div>
                                <div className="form--group">
                                    <button type="submit" className="btn btn--red">Modifier</button>
                                </div>
                                <div className="form--group">
                                    <Button color="white" title="Retour" className={null} onClick={() => {
                                        setSuccess(null);
                                        setError(null);
                                        window.history.back()
                                    }} />
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </IonContent>
                {
                    isLoading && (
                        <Loader type="full" />
                    )
                }
            </IonPage>
        </LoggedInRoute>
    );
}

export default EditUsername;