import { IonInfiniteScroll, IonList } from "@ionic/react"
import Card, { CardProps } from "./Card";
import Loader from "./loader";

interface CardListProps {
    loadMore: any,
    disabled: boolean,
    data: Array<CardProps>,
    isLoading: boolean
}

const CardList = (props: CardListProps) => {
    return (
        <IonList color="transparent">
            {
                props.isLoading ? (
                    <Loader type="in-content" />
                ) : (
                    props.data.map((element, index) => (
                        <Card key={index} title={element.title} subTitle={element.subTitle} type={element.type} link={element.link} />
                    ))
                )
            }
            <IonInfiniteScroll
                onIonInfinite={props.loadMore}
                threshold="100px"
                disabled={props.disabled}>
                <Loader type="in-content" />
            </IonInfiniteScroll>
        </IonList>
    );
}

export default CardList;