import { IonContent, IonPage } from "@ionic/react";
import { Link } from "react-router-dom";
import TopBar from "../components/TopBar";
import LoggedInRoute from "../tools/LoggedInRoute";
import DeleteIcon from "../resources/icons/delete.svg";
import UsernameIcon from '../resources/icons/address-card.svg';
import PasswordIcon from '../resources/icons/shield.svg';
import EmailIcon from '../resources/icons/mailbox.svg';

const UserSettings: React.FC = () => {
    return (
        <LoggedInRoute>
            <IonPage>
                <TopBar title={null} />
                <IonContent>
                    <div className="container page--user-settings">
                        <div className="settings-list">
                            <Link to="/settings/user/username" className="settings-item">
                                <div className="settings-item-icon">
                                    <img src={UsernameIcon} />
                                </div>
                                <div className="settings-item-data">
                                    <p className="settings-item-title">Modifier le nom d'utilisateur</p>
                                    <p className="settings-item-sub-title">Changez votre nom public et de connexion</p>
                                </div>
                            </Link>
                            <Link to="/settings/user/password" className="settings-item">
                                <div className="settings-item-icon">
                                    <img src={PasswordIcon} />
                                </div>
                                <div className="settings-item-data">
                                    <p className="settings-item-title">Modifier le mot de passe</p>
                                    <p className="settings-item-sub-title">Changez votre mot de passe</p>
                                </div>
                            </Link>
                            <Link to="/settings/user/email" className="settings-item">
                                <div className="settings-item-icon">
                                    <img src={EmailIcon} />
                                </div>
                                <div className="settings-item-data">
                                    <p className="settings-item-title">Modifier l'adresse e-mail</p>
                                    <p className="settings-item-sub-title">Changez votre adresse e-mail pour récupérer votre compte</p>
                                </div>
                            </Link>
                            <Link to="/settings/user/delete" className="settings-item">
                                <div className="settings-item-icon">
                                    <img src={DeleteIcon} />
                                </div>
                                <div className="settings-item-data">
                                    <p className="settings-item-title">Supprimer mon compte</p>
                                    <p className="settings-item-sub-title">Supprimez votre compte et tous vos spoils de la plateforme.</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        </LoggedInRoute>
    );
}

export default UserSettings;