interface BackgroundImageProps {
    image: any
}

const BackgroundImage = (props: BackgroundImageProps) => {
    return (
        <div className="background-image">
            <img className="background-image--img" src={props.image} />
            <div className="background-image--placeholder" />
        </div>
    );
}

export default BackgroundImage;