interface ButtonProps {
    title: string,
    color: string,
    onClick: any,
    className: string | null
}

const Button = (props: ButtonProps) => {
    return (
        <button className={`btn btn--${props.color}${props.className ? ` ${props.className}` : ''}`} onClick={props.onClick}>{props.title}</button>
    );
}

export default Button;