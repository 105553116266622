import React, { useState } from 'react'
import { IonCard, IonCardContent, IonCardHeader, IonContent, IonPage } from "@ionic/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import Button from "../components/Button";
import TopBar from "../components/TopBar";
import LoggedInRoute from "../tools/LoggedInRoute";
import { SIGN_OUT, useAuth } from '../context/AuthContext';
import { deleteUser, getCurrentUserDetail } from '../tools/auth';
import Loader from '../components/loader';

const DeleteUser: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const { authDispatch, authState } = useAuth();

    const initialeValue = {
        username: ''
    }

    const onSubmit = (value: any) => {
        setIsLoading(true);
        setError(null);
        //Check username
        getCurrentUserDetail(authState.userToken).then((user: any) => {
            if (user) {
                if (user.username === value.username) {
                    deleteUser(authState.userToken, user.id).then((response) => {
                        if (response === true) {
                            setIsLoading(false);
                            setSuccess(true);
                        } else {
                            setIsLoading(false);
                            setError('Une erreur est survenue');
                        }
                    });
                } else {
                    setIsLoading(false);
                    setError('Le pseudo saisi ne correspond pas au compte actuellement connecté.')
                }
            } else {
                setIsLoading(false);
                setError('Une erreur est survenue');
            }
        });
    }

    const validationSchema = Yup.object({
        username: Yup.string().required('Obligatoire')
    });

    return (
        <LoggedInRoute>
            <IonPage>
                <TopBar title={null} />
                <IonContent>
                    <div className="container page--delete-user">
                        {
                            success ? (
                                <>
                                    <h2 className="title">À bientôt sur le Spoil&nbsp;O&nbsp;Matic</h2>
                                    <p>Votre compte à bien été supprimer.</p>
                                    <Button title="Revenir à la connexion" color="red" className={null} onClick={() => {
                                        authDispatch({ type: SIGN_OUT });
                                    }} />
                                </>
                            ) : (
                                <>
                                    <h2 className="title">Supprimer mon compte</h2>
                                    {
                                        error && (
                                            <IonCard color="danger">
                                                <IonCardHeader className="title">Oups</IonCardHeader>
                                                <IonCardContent>
                                                    <p>{error}</p>
                                                </IonCardContent>
                                            </IonCard>
                                        )
                                    }
                                    <p className="text">Nous sommes navré d'apprendre votre départ.</p>
                                    <p className="text">Si vous souhaiter réelement cloturer votre compte, veuillez entrer votre pseudo et cliquer sur confirmer.</p>
                                    <p className="text">Veuillez noter qu'en supprimant votre compte, tous vos spoils ainsi que tous les informations liée a votre comptes seront définitivement supprimé.</p>

                                    <Formik
                                        initialValues={initialeValue}
                                        onSubmit={onSubmit}
                                        validationSchema={validationSchema}>
                                        <Form className="form">
                                            <div className="form--group">
                                                <label htmlFor="username">Votre pseudo</label>
                                                <Field type="text" name="username" />
                                                <ErrorMessage name="username" component="label" className="form--error" />
                                            </div>
                                            <div className="form--group">
                                                <button type="submit" className="btn btn--red">Supprimer mon compte</button>
                                            </div>
                                            <div className="form--group">
                                                <Button title="Retour" className={null} color="white" onClick={() => {
                                                    window.history.back();
                                                }} />
                                            </div>
                                        </Form>
                                    </Formik>
                                </>
                            )
                        }
                    </div>
                </IonContent>
                {
                    isLoading && (
                        <Loader type="full" />
                    )
                }
            </IonPage>
        </LoggedInRoute>
    )
}

export default DeleteUser;