import { IonContent, IonHeader, IonList, IonMenu, IonMenuToggle, IonToolbar } from '@ionic/react';
import { useAuth } from '../context/AuthContext';
import MenuItem from './MenuItem';
import LogoSom from '../resources/logos/som_logo_white.svg';
import IconUser from '../resources/icons/user.svg';
import IconSettings from '../resources/icons/settings.svg';
import IconLogout from '../resources/icons/logout.svg';

const Nav: React.FC = () => {


  const { authState } = useAuth();

  return (
    <IonMenu className="navbar" contentId="main-view">
      <IonHeader>
        <IonToolbar className="navbar--header" color="transparent">
          <img className="navbar--logo" src={LogoSom} />
        </IonToolbar>
      </IonHeader>
      <IonContent className="navbar--body">
        <IonList id="settings-list" class="navbar--list">
          <IonMenuToggle>
            {
              authState.userToken ? (
                <>
                  <IonList color="transparent" class="navbar--list">
                    <MenuItem link='/settings/user' name='Modifier le profil' icon={IconUser} />
                    <MenuItem link='/settings/feed' name='Modifier le feed' icon={IconSettings} />
                    <MenuItem link='/logout' name="Se déconnecter" icon={IconLogout} />
                  </IonList>
                </>
              ) : (
                <>
                  <MenuItem link='/login' name="Se connecter" icon={IconUser} />
                </>
              )
            }
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
}

export default Nav;