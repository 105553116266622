import { Link } from "react-router-dom";

interface LastUpdateItemProps {
    title: string,
    id: string
}

const LastUpdateItem = (props: LastUpdateItemProps) => {
    return (
        <Link to={`/title/${props.id}`} className="last-updates--item">
            <div className="item--thumbnail">
                <p className="thumbnail--letter">{props.title.substr(0, 1)}</p>
            </div>
            <div className="item--content">
                <p className="item--title">{props.title}</p>
            </div>
        </Link>
    );
}

export default LastUpdateItem;