import axios from "axios";
import { SIGN_OUT } from "../context/AuthContext";
import { CHECK_CODE, CHECK_EMAIL, CHECK_USERNAME, CURRENT_USER, DELETE_USER, IS_FOLLOWING, LOGIN_URL, LOGOUT, REGISTER_URL, RESET_PASSWORD, SEND_CODE, START_FOLLOW, STOP_FOLLOW, UPDATE_USER } from "./serverEndpoints";

export const CODE_EXPIRED: string = 'CODE_EXPIRED';
export const EMAIL_NOT_FOUND: string = 'EMAIL_NOT_FOUND';
export const ALREADY_HAS_CODE: string = 'ALREADY_HAS_CODE';
export const CODE_SEND: string = "CODE_SEND";
export const MISSING_VALUES: string = "MISSING_VALUES";
export const CODE_INVALID: string = "CODE_INVALID";
export const CODE_VALID: string = "CODE_VALID";
export const NOT_VALID_EMAIL: string = "NOT_VALID_EMAIL";
export const USERNAME_ALREADY_TAKEN: string = "USERNAME_ALREADY_TAKEN";
export const USERNAME_TOO_SHORT: string = "USERNAME_TOO_SHORT";
export const EMAIL_ALREADY_TAKEN: string = "EMAIL_ALREADY_TAKEN";
export const OK: string = "OK";
export const ERROR: string = "ERROR";

/**
 * Function used to authenticate a user
 * @param username The username
 * @param password The password
 * @returns The token with user details or null
 */
export const login = async (username: string, password: string): Promise<Object | null> => {
    if (username === '' || password === '') {
        //Missing values
        return null;
    } else {

        const formData: FormData = new FormData();
        formData.append('grant_type', 'password');
        formData.append('client_id', process.env.REACT_APP_CLIENT ? process.env.REACT_APP_CLIENT : '');
        formData.append('client_secret', process.env.REACT_APP_SECRET ? process.env.REACT_APP_SECRET : '');
        formData.append('username', username);
        formData.append('password', password);

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data'
        }

        try {
            const result: any = await axios.post(LOGIN_URL, formData, { headers });
            let response: any = result.data;
            const user: any = await getCurrentUserDetail(response.access_token);
            response = { ...response, user };
            return response;
        } catch (error) {
            return null;
        }
    }
}

/**
 * Function used to register a user
 * @param username The username
 * @param password The password
 * @param password_confirmation The password confirmation 
 * @param email The email
 * @returns An object if the operation is done
 */
export const registerUser = async (username: string, password: string, password_confirmation: string, email: string): Promise<Object> => {

    let response = {
        error: true,
        message: ERROR,
        user: null
    };

    if (username === '' || password === '' || password_confirmation === '' || email === '') {
        // Missing values
        return response;
    } else {
        // Check if is a valid e-mail
        if (!checkEmail(email)) {
            response.message = NOT_VALID_EMAIL;
            return response;
        }

        //Check username
        const username_result = await checkUsername(username);
        if (username_result === OK) {
            // Prepare the request
            const formData: FormData = new FormData();
            formData.append('client_id', process.env.REACT_APP_CLIENT ? process.env.REACT_APP_CLIENT : '');
            formData.append('client_secret', process.env.REACT_APP_SECRET ? process.env.REACT_APP_SECRET : '');
            formData.append('email', email);
            formData.append('password', password);
            formData.append('password_confirmation', password_confirmation);
            formData.append('username', username);

            const headers = {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }

            try {
                const result: any = await axios.post(REGISTER_URL, formData, { headers });
                response.error = false;
                response.message = OK;
                response.user = result.data.user;

                return response;
            } catch (error: any) {
                //Server return an error
                switch (error.response.data.message) {
                    case 'The given data was invalid.': {
                        if (error.response.data.errors.email) {
                            response.message = EMAIL_ALREADY_TAKEN;
                            return response;
                        } else if (error.response.data.errors.username) {
                            response.message = USERNAME_ALREADY_TAKEN;
                            return response;
                        } else {
                            return response
                        }
                    }
                    default: {
                        return response;
                    }
                }
            }
        } else {
            //Username is not valid
            response.message = username_result;
            return response;
        }
    }
}

/**
 * Function used to send a verification to a user
 * @param email The user mail
 * @returns code result
 */
export const sendCode = async (email: string): Promise<string> => {
    if (email === '') {
        return MISSING_VALUES;
    } else {
        const formData: FormData = new FormData();
        formData.append('client_id', process.env.REACT_APP_CLIENT ? process.env.REACT_APP_CLIENT : '');
        formData.append('client_secret', process.env.REACT_APP_SECRET ? process.env.REACT_APP_SECRET : '');
        formData.append('email', email);

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data'
        }

        try {
            await axios.post(SEND_CODE, formData, { headers });
            return CODE_SEND;
        } catch (error: any) {
            switch (error.response.data.message) {
                case "User not found": {
                    return EMAIL_NOT_FOUND;
                }
                case "Already has a valid code": {
                    return ALREADY_HAS_CODE;
                }
                default: {
                    return ERROR;
                }
            }
        }
    }
}

/**
 * Function used to check if verification code is valid
 * @param code The code
 * @param email The user email
 * @returns A string code
 */
export const checkCode = async (code: string, email: string): Promise<string> => {
    if (code === '' || email === '') {
        return MISSING_VALUES;
    } else {
        const formData: FormData = new FormData();
        formData.append('client_id', process.env.REACT_APP_CLIENT ? process.env.REACT_APP_CLIENT : '');
        formData.append('client_secret', process.env.REACT_APP_SECRET ? process.env.REACT_APP_SECRET : '');
        formData.append('email', email);
        formData.append('code', code);

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data'
        }

        try {
            await axios.post(CHECK_CODE, formData, { headers });
            return CODE_VALID
        } catch (error: any) {
            switch (error.response.data.message) {
                case "User not found": {
                    return EMAIL_NOT_FOUND;
                }
                case "code not valid": {
                    return CODE_INVALID;
                }
                case "Expired": {
                    return CODE_EXPIRED;
                }
                default: {
                    return ERROR;
                }
            }
        }
    }
}


/**
 * Function used to reset a passord from a validation code
 * @param email The user email
 * @param code The validation code
 * @param password The new password
 * @returns A string result code
 */
export const resetPassword = async (email: string, code: string, password: string): Promise<String> => {
    if (email === '' || code === '' || password === '') {
        return MISSING_VALUES;
    } else {
        const formData: FormData = new FormData();
        formData.append('client_id', process.env.REACT_APP_CLIENT ? process.env.REACT_APP_CLIENT : '');
        formData.append('client_secret', process.env.REACT_APP_SECRET ? process.env.REACT_APP_SECRET : '');
        formData.append('email', email);
        formData.append('code', code);
        formData.append('password', password);

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data'
        }

        try {
            await axios.post(RESET_PASSWORD, formData, { headers });
            return OK;
        } catch (error: any) {
            switch (error.response.data.message) {
                case 'code not valid': {
                    return CODE_INVALID;
                }
                case 'User not found': {
                    return EMAIL_NOT_FOUND;
                }
                case 'Expired': {
                    return CODE_EXPIRED;
                }
                default: {
                    return ERROR;
                }
            }
        }
    }
}

/**
 * Function used to recover current user details
 * @param token The barer token
 * @returns The user object or null
 */
export const getCurrentUserDetail = async (token: string): Promise<Object | null> => {
    if (token === '') {
        return null;
    }

    try {
        const result: any = await axios.get(CURRENT_USER, { headers: { 'Authorization': `Bearer ${token}` } });
        return result.data;
    } catch (error) {
        return null;
    }
}

/**
 * Function used to check if a username is valid
 * @param username the username
 * @returns A string code
 */
const checkUsername = async (username: string): Promise<string> => {
    if (username !== '' && username.length >= 3) {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data'
        }
        try {
            const response: any = await axios.get(`${CHECK_USERNAME}/${username}?client_id=${process.env.REACT_APP_CLIENT}&client_secret=${process.env.REACT_APP_SECRET}`, { headers });
            if (response.data.exist === true) {
                return USERNAME_ALREADY_TAKEN;
            } else if (response.data.isValid === true && response.data.exist === false) {
                return OK;
            } else {
                return USERNAME_TOO_SHORT;
            }
        } catch (error) {
            return ERROR;
        }
    } else {
        return USERNAME_TOO_SHORT;
    }
}

/**
 * Check if is a valid e-mail
 * @param email The email
 * @returns If is valid
 */
export const checkEmail = (email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(email) === false) {
        return false;
    }
    else {
        return true
    }
};

/**
 * Update a user
 * @param token The barer token
 * @param username The new username (Set to null to keep current data)
 * @param email The new email (Set to null to keep current data)
 * @param password The new password (Set to null to keep current data)
 * @param genres The complete array of liked topics (Set to null to keep current data)
 * @returns string code
 */
export const updateUser = async (id: string, token: string, username: string | null = null, email: string | null = null, password: string | null = null, genres: Array<string> | null = null): Promise<string> => {
    if (token === '' || id == '') {
        return MISSING_VALUES;
    }
    let hasValues = false;

    //Set up request
    const formData: FormData = new FormData();
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    if (username !== null) {
        formData.append('username', username);
        hasValues = true;
    }

    if (email !== null) {
        formData.append('email', email);
        hasValues = true;
    }

    if (password !== null) {
        formData.append('password', password);
        hasValues = true;
    }

    if (genres !== null) {
        if (genres.length > 0) {
            genres.forEach((genre) => {
                formData.append('genres[]', genre);
            });
            hasValues = true;
        }
    }

    if (hasValues) {
        //Send request
        try {
            await axios.post(`${UPDATE_USER}/${id}`, formData, { headers });
            return OK;
        } catch (error: any) {
            return ERROR;
        }

    } else {
        //Missing values
        return MISSING_VALUES;
    }
}

/**
 * Validate an e-mail
 * @param id The user ID
 * @param code The validation code
 * @returns string code
 */
export const validateEmail = async (id: string, code: string): Promise<string> => {
    if (id === '' || code === '') {
        return MISSING_VALUES
    }

    //Prepare request
    const formData: FormData = new FormData();
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
    }

    formData.append('client_id', process.env.REACT_APP_CLIENT ? process.env.REACT_APP_CLIENT : '');
    formData.append('client_secret', process.env.REACT_APP_SECRET ? process.env.REACT_APP_SECRET : '');
    formData.append('user_id', id);
    formData.append('code', code);

    try {
        await axios.post(CHECK_EMAIL, formData, { headers });
        return OK;
    } catch (error: any) {
        switch (error.response.data.message) {
            case "Expired": {
                return CODE_EXPIRED;
            }
            case "code not valid": {
                return CODE_INVALID
            }
            default: {
                return ERROR;
            }
        }
    }
}

/**
 * Function used to logout the current user
 * @param authDispatcher The dispatcher of the auth context
 */
export const logout = async (authDispatcher: any, token: string): Promise<void> => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
    try {
        await axios.delete(LOGOUT, { headers });
    } catch (error) {
        //console.log(error);
    }
    localStorage.clear();
    authDispatcher({ type: SIGN_OUT });

}

/**
 * Function used to get if a user is curently following a specific user
 * @param token The barer token of current user
 * @param userID The desired user
 * @return boolean true is user is following
 */
export const isFollowing = async (token: string, userID: string) => {
    if (token === '' || userID === '') {
        return MISSING_VALUES;
    }

    //Set up request
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response: any = await axios.get(`${IS_FOLLOWING}/${userID}`, { headers });
        return response.data.following;
    } catch (error) {
        return ERROR;
    }
}

/**
 * Function used to start following a user
 * @param token The barer token of current user
 * @param userID The desired user
 * @return boolean true is user is following
 */
export const startFollow = async (token: string, userID: string) => {
    if (token === '' || userID === '') {
        return MISSING_VALUES;
    }

    //Set up request
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response: any = await axios.post(`${START_FOLLOW}/${userID}`, new FormData(), { headers });
        return response.data.action;
    } catch (error) {
        return ERROR;
    }
}

/**
 * Function used to stop following a user
 * @param token The barer token of current user
 * @param userID The desired user
 * @return boolean true is user is following
 */
export const stopFollow = async (token: string, userID: string) => {
    if (token === '' || userID === '') {
        return MISSING_VALUES;
    }

    //Set up request
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response: any = await axios.delete(`${STOP_FOLLOW}/${userID}`, { headers });
        return response.data.action;
    } catch (error) {
        return ERROR;
    }
}

/**
 * Function used to delete the current user
 * @param token The barer token of current user
 * @param id The current user id
 * @return If success (boolean)
 */
export const deleteUser = async (token: string, id: string): Promise<boolean> => {
    //Set up request
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        await axios.delete(`${DELETE_USER}/${id}`, { headers });
        return true;
    } catch (error) {
        return false;
    }
}