import { IonCol } from "@ionic/react"
import { Link } from "react-router-dom"

interface BottomBarItemProps {
    link: string,
    icon: string
}

const BottomBarItem = (props: BottomBarItemProps) => {
    return (
        <IonCol className="bottom-bar--item">
            <Link to={props.link}>
                <img src={props.icon} />
            </Link>
        </IonCol>
    );
}

export default BottomBarItem;