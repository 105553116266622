import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage } from "@ionic/react"
import { useState } from 'react';
import { ALREADY_HAS_CODE, checkCode, CODE_EXPIRED, CODE_INVALID, CODE_VALID, EMAIL_NOT_FOUND, ERROR, OK, resetPassword, sendCode } from "../tools/auth";
import LoggedOutRoute from "../tools/LoggedOutRoute";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import TopBar from "../components/TopBar";
import ProgressBar from "../components/ProgressBar";
import Button from "../components/Button";
import Loader from "../components/loader";
import Cimena from '../resources/images/cinema.jpg';
import BackgroundImage from "../components/BackgroundImage";

const ForgotPassword: React.FC = () => {

    const [email, setEmail] = useState<string | null>(null)
    const [code, setCode] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [information, setInformation] = useState<string | null>(null);
    const [progress, setProgress] = useState<number>(33);
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const validation1Schema = Yup.object({
        email: Yup.string().required('Obligatoire').email('Doit être une adresse e-mail valide')
    });

    const validation2Schema = Yup.object({
        verification_code: Yup.string().required('Obligatoire')
    });

    const validation3Schema = Yup.object({
        new_password: Yup.string().required('Obligatoire').min(8, 'Doit contenir au minimum 8 charactères').matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,255}$/g, 'Dois contenir au minimum 1 majuscule, 1 minuscule et 1 chiffre'),
        new_password_confirmation: Yup.string().required('Obligatoire').oneOf([Yup.ref('new_password'), null], 'Les mot de passes ne correspondent pas')
    });

    const initialValues1 = {
        email: ''
    };

    const initialValues2 = {
        verification_code: ''
    };

    const initialValues3 = {
        new_password: '',
        new_password_confirmation: ''
    };

    //Request code
    const handleCode = (data: any) => {
        if (data.email !== null) {
            setIsLoading(true);
            //Send code
            sendCode(data.email).then((result: string) => {
                switch (result) {
                    case EMAIL_NOT_FOUND: {
                        setError('Veuillez vérifier votre adresse e-mail');
                        setInformation(null);
                        break;
                    }
                    case ALREADY_HAS_CODE: {
                        setError(null);
                        setInformation("Un code de vérification vous a déjà été envoyé lors de ces 30 dernières minutes.");
                        setEmail(data.email);
                        setProgress(66);
                        break;
                    }
                    case ERROR: {
                        setError('Une erreur est survenue');
                        setInformation(null);
                        break;
                    }
                    default: {
                        setError(null);
                        setInformation(null);
                        setEmail(data.email);
                        setProgress(66);
                        break;
                    }
                }
                setIsLoading(false);
            });
        }
    }

    //Check code
    const handleCheck = (data: any) => {
        if (data.verification_code !== null) {
            setIsLoading(true);
            checkCode(data.verification_code, email ? email : '').then((response) => {
                switch (response) {
                    case CODE_VALID: {
                        setError(null);
                        setInformation(null);
                        setCode(data.verification_code);
                        setProgress(100);
                        break;
                    }
                    case CODE_INVALID: {
                        setError("Code invalide");
                        setInformation(null);
                        break;
                    }
                    case CODE_EXPIRED: {
                        setError("Code expiré. Veuillez réessayer.");
                        setInformation(null);
                        setEmail(null);
                        break;
                    }
                    case EMAIL_NOT_FOUND: {
                        setError("Veuillez vérifier votre adresse e-mail");
                        setInformation(null);
                        setEmail(null);
                        break;
                    }
                    default: {
                        setError("Une erreur est survenue");
                        setInformation(null);
                        setEmail(null);
                        break;
                    }
                }
                setIsLoading(false);
            });
        }
    }

    //Reset password
    const handlePassword = (data: any) => {
        if (data.new_password === data.new_password_confirmation && data.new_password !== '' && data.new_password_confirmation !== '') {
            setIsLoading(true);
            resetPassword(email ? email : '', code ? code : '', data.new_password).then((result) => {
                switch (result) {
                    case OK: {
                        setError(null);
                        setSuccess(OK);
                        break;
                    }
                    case EMAIL_NOT_FOUND: {
                        setError("Veuillez vérifier votre adresse e-mail");
                        setInformation(null);
                        setEmail(null);
                        setCode(null);
                        break;
                    }
                    case CODE_INVALID: {
                        setError("Code invalide");
                        setInformation(null);
                        setEmail(null);
                        setCode(null);
                        break;
                    }
                    case CODE_EXPIRED: {
                        setError("Code expiré. Veuillez réessayer.");
                        setInformation(null);
                        setEmail(null);
                        setCode(null);
                        break;
                    }
                    default: {
                        setError("Une erreur est survenue");
                        setInformation(null);
                        setEmail(null);
                        setCode(null);
                        break;
                    }
                }
                setIsLoading(false);
            });
        } else {
            setError('Veuillez vérifier vos valeurs');
        }
    }

    return (
        <LoggedOutRoute>
            <IonPage>
                <TopBar title={null} />
                <IonContent fullscreen scrollY={false}>
                    <div className="page--forgot container">
                        <ProgressBar progress={progress} />
                        {
                            error !== null && (
                                <IonCard color="danger" >
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            Oups
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <p>{error}</p>
                                    </IonCardContent>
                                </IonCard>
                            )
                        }
                        {
                            information !== null && (
                                <IonCard color="primary" >
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            Information
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <p>{information}</p>
                                    </IonCardContent>
                                </IonCard>
                            )
                        }
                        {
                            email === null ? (
                                <>
                                    <h1 className="title">Mot de passe oublié</h1>
                                    <Formik
                                        initialValues={initialValues1}
                                        validationSchema={validation1Schema}
                                        onSubmit={handleCode}>
                                        <Form className="form form--full-height">
                                            <div className="container--form">
                                                <div className="form--group">
                                                    <label htmlFor="email">Adresse e-mail</label>
                                                    <Field name="email" type="email" />
                                                    <ErrorMessage name="email" component="label" className="form--error" />
                                                </div>
                                            </div>
                                            <div className="container--form--btn">
                                                <div className="form--group btn btn--red">
                                                    <input type="submit" value="Envoyer le code" />
                                                </div>
                                                <div className="form--group">
                                                    <Button className={null} title="Retour" color="white" onClick={() => { window.history.back() }} />
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </>
                            ) : code === null ? (
                                <>
                                    <h1 className="title">Confirmation</h1>
                                    <p>Nous t'avons envoyé un code de vérification à ton adresse e-mail.</p>
                                    <Formik
                                        initialValues={initialValues2}
                                        validationSchema={validation2Schema}
                                        onSubmit={handleCheck}>
                                        <Form className="form form--full-height">
                                            <div className="container--form">
                                                <div className="form--group">
                                                    <label htmlFor="verification_code">Code</label>
                                                    <Field type="text" name="verification_code" />
                                                    <ErrorMessage name="verification_code" component="label" className="form--error" />
                                                </div>
                                            </div>
                                            <div className="container--form--btn">
                                                <div className="form--group btn btn--red">
                                                    <input type="submit" value="Suivant" />
                                                </div>
                                                <div className="form--group">
                                                    <Button className={null} title="Retour" color="white" onClick={() => {
                                                        setEmail(null);
                                                        setInformation(null);
                                                        setProgress(33);
                                                    }} />
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </>
                            ) : success === null ? (
                                <>
                                    <h1 className="title">Modifier le mot de passe</h1>
                                    <Formik
                                        initialValues={initialValues3}
                                        validationSchema={validation3Schema}
                                        onSubmit={handlePassword}>
                                        <Form className="form form--full-height">
                                            <div className="container--form">
                                                <div className="form--group">
                                                    <label htmlFor="new_password">Mot de passe</label>
                                                    <Field type="password" name="new_password" />
                                                    <ErrorMessage name="new_password" component="label" className="form--error" />
                                                </div>
                                                <div className="form--group">
                                                    <label htmlFor="new_password_confirmation">Confirmer mot de passe</label>
                                                    <Field type="password" name="new_password_confirmation" />
                                                    <ErrorMessage name="new_password_confirmation" component="label" className="form--error" />
                                                </div>
                                            </div>
                                            <div className="container--form--btn">
                                                <div className="form--group btn btn--red">
                                                    <input type="submit" value="Confirmer" />
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </>
                            ) : (
                                <>
                                    <IonCard color="success">
                                        <IonCardHeader>
                                            <IonCardTitle>Mot de passe réinitialisé</IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <p>Votre mot de passe à bien été réinitialisé</p>
                                            <Button className={null} title="Retour à la connexion" color="white" onClick={() => { window.history.back() }} />
                                        </IonCardContent>
                                    </IonCard>
                                </>
                            )
                        }
                    </div>
                </IonContent>
                {
                    isLoading && (
                        <Loader type="full" />
                    )
                }
                <BackgroundImage image={Cimena} />
            </IonPage>
        </LoggedOutRoute>
    );
}

export default ForgotPassword;