// AUTH
export const LOGIN_URL: string = process.env.REACT_APP_BACK + '/oauth/token';
export const REGISTER_URL: string = process.env.REACT_APP_BACK + '/api/auth/register';
export const RESET_PASSWORD: string = process.env.REACT_APP_BACK + '/api/auth/reset-password';
export const SEND_CODE: string = process.env.REACT_APP_BACK + '/api/auth/send-code';
export const CHECK_CODE: string = process.env.REACT_APP_BACK + '/api/auth/check-code';
export const CHECK_EMAIL: string = process.env.REACT_APP_BACK + '/api/auth/check-mail';
export const LOGOUT: string = process.env.REACT_APP_BACK + '/api/user/logout';

// USER
export const CURRENT_USER: string = process.env.REACT_APP_BACK + '/api/user';
export const CHECK_USERNAME: string = process.env.REACT_APP_BACK + '/api/user/username/check';
export const UPDATE_USER: string = process.env.REACT_APP_BACK + '/api/user/update';
export const IS_FOLLOWING: string = process.env.REACT_APP_BACK + '/api/follow/is-following';
export const START_FOLLOW: string = process.env.REACT_APP_BACK + '/api/follow/connect';
export const STOP_FOLLOW: string = process.env.REACT_APP_BACK + '/api/follow/disconnect';
export const FIND_USER: string = process.env.REACT_APP_BACK + '/api/user';
export const GET_FOLLOWING: string = process.env.REACT_APP_BACK + '/api/follow/following';
export const GET_FOLLOWER: string = process.env.REACT_APP_BACK + '/api/follow/followers';
export const DELETE_USER: string = process.env.REACT_APP_BACK + '/api/user';

//Genres
export const GET_GENRES: string = process.env.REACT_APP_BACK + '/api/genres';

//Spoils
export const SPOIL_FEED: string = process.env.REACT_APP_BACK + '/api/spoil';
export const GET_SPOIL: string = process.env.REACT_APP_BACK + '/api/spoil';
export const ADD_SPOIL: string = process.env.REACT_APP_BACK + '/api/spoil/add';

//Title
export const SEARCH_TITLE: string = process.env.REACT_APP_BACK + '/api/title/search';
export const LAST_UPDATE: string = process.env.REACT_APP_BACK + '/api/title/last-update';
export const GET_TITLE: string = process.env.REACT_APP_BACK + '/api/title';

//Content
export const SEARCH: string = process.env.REACT_APP_BACK + '/api/search';