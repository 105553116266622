import React, { useState, useEffect } from 'react';
import { get_last_updates, UpdatesTitle } from '../tools/content';
import LastUpdateItem from "./LastUpdateItem";
import Loader from './loader';
import Logo from '../resources/logos/som_logo_white.svg';

const LastUpdate: React.FC = () => {
    const [titles, setTitles] = useState<Array<UpdatesTitle>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        get_last_updates().then((response: Array<UpdatesTitle>) => {
            setTitles(response);
            setIsLoading(false);
        })
    }, []);

    return (
        <section className="last-updates">
            <div className="LogoHome"><img src={Logo} alt="logo" /></div>
            <h3 className="last-updates--title">Les nouveautés de la plateforme</h3>
            {
                isLoading ? (
                    <Loader type="in-content" />
                ) : titles.length > 0 ? (
                    <div className="last-updates--list">
                        {
                            titles.map((value) => (
                                <LastUpdateItem key={value.id} title={value.title} id={value.id} />
                            ))
                        }
                    </div>
                ) : (
                    <p className="last-updates--loading">Les dernières nouveautés sont actuellement indisponibles.</p>
                )
            }
        </section>
    );
}

export default LastUpdate;