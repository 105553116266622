import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from "@ionic/react";

interface MenuItemProps {
    link: string,
    name: string,
    icon: string
}

const MenuItem = (props: MenuItemProps) => {
    return (
        <IonItem className="navbar--item" routerLink={props.link} routerDirection="none" lines="none" detail={false} color="transparent">
            <IonGrid>
                <IonRow>
                    <IonCol size="2" className="navbar--icon">
                        <img src={props.icon} />
                    </IonCol>
                    <IonCol className="navbar--label">
                        <IonLabel>{props.name}</IonLabel>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonItem>
    );
}

export default MenuItem;