import { Lottie } from "@crello/react-lottie";
import Animation from '../resources/lottie/som_camera.json';

interface CommingSoonProps {
    title: string,
    type: 'in-content' | 'full'
}

const CommingSoon = (props: CommingSoonProps) => {
    return (
        <div className={`coming-soon coming-soon--${props.type}`}>
            <p className="title title--small">{props.title}</p>
            <Lottie className="lottie" config={{
                animationData: Animation,
                loop: true,
                autoplay: true
            }} />
        </div>
    )
}

export default CommingSoon;