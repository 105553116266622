import { Lottie } from '@crello/react-lottie';
import Animation from '../resources/lottie/som_loader.json';

interface LoaderProps {
    type: 'in-content' | 'full'
}

const Loader = (props: LoaderProps) => {
    return (
        <div className={`loader loader--${props.type}`}>
            <Lottie className="lottie" config={{
                animationData: Animation,
                loop: true,
                autoplay: true
            }} />
        </div>
    );
}

export default Loader;