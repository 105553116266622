import React, { useContext, useReducer } from "react";

const AuthContext: React.Context<any> = React.createContext(undefined);
export const RESTORE_TOKEN: string = 'RESTORE_TOKEN';
export const SIGN_IN: string = 'SIGN_IN';
export const SIGN_OUT: string = 'SIGN_OUT';
export const REFRESH_USER: string = 'REFRESH_USER';

const authReducer = (state: Object, action: any) => {
    switch (action.type) {
        case RESTORE_TOKEN: {
            return {
                ...state,
                userToken: action.token,
                user_id: action.user_id,
                username: action.username,
                email: action.email,
                nb_spoil: action.nb_spoil,
                isLoading: false,
            };
        }
        case SIGN_IN: {
            return {
                ...state,
                userToken: action.token,
                user_id: action.user_id,
                username: action.username,
                email: action.email,
                nb_spoil: action.nb_spoil,
            };
        }
        case SIGN_OUT: {
            return {
                ...state,
                userToken: null,
                user_id: null,
                username: null,
                email: null,
                nb_spoil: null,
            };
        }
        case REFRESH_USER: {
            return {
                ...state,
                user_id: action.user_id,
                username: action.username,
                email: action.email,
                nb_spoil: action.nb_spoil,
            };
        }
        default: {
            throw new Error('Bad context type');
        }
    }
}

interface AuthProviderProps {
    children?: React.ReactNode
}

export function AuthProvider(props: AuthProviderProps) {
    const [authState, authDispatch] = useReducer(authReducer, {
        userToken: null,
        user_id: null,
        username: null,
        email: null,
        nb_spoil: null,
        isLoading: true,
    });
    const value = { authState, authDispatch };

    return (<AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>);
}

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('Unable to find AuthContext');
    }
    return context;
}