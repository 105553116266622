import React, { useRef, useState } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import TopBar from "../components/TopBar";
import LoggedInRoute from "../tools/LoggedInRoute";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { add_spoil, BANNED, OK, SearchTitleInterface, search_title } from '../tools/content';
import { useAuth } from '../context/AuthContext';
import { NOT_VALID_EMAIL } from '../tools/auth';
import { Redirect } from 'react-router-dom';
import Loader from '../components/loader';

const SpoilWriter: React.FC = () => {

    const spoilRef = useRef<any>();

    const [spoilLength, setSpoilLength] = useState<number>(0);
    const [titleInputValue, setTitleInputValue] = useState<string>('');
    const [selectedValue, setSelectedValue] = useState<SearchTitleInterface | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { authState } = useAuth();

    const handleSelectInputChange = (value: string) => {
        setTitleInputValue(value);
    }

    const handleSelectChange = (values: any) => {
        setSelectedValue(values);
    }

    const loadOptions = (titleInputValue: string) => {
        return search_title(titleInputValue);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (spoilLength === 0 || !selectedValue) {
            setError('Veuillez remplir tous les champs.');
        } else if (spoilLength > 130) {
            setError('Un spoil ne peut pas excéder 130 caractères.')
        } else {
            //Send spoil
            setIsLoading(true);
            add_spoil(authState.userToken, authState.user_id, selectedValue!.id, spoilRef.current.value).then((result: string) => {
                switch (result) {
                    case NOT_VALID_EMAIL: {
                        setError('Seul les comptes avec une adresse e-mail vérifiée peuvent envoyer des spoils.');
                        break;
                    }
                    case BANNED: {
                        setError('Ce compte fait l\'objet d\'une restriction d\'envoi de spoils à la suite d\'un non respect des règles de la communauté.');
                        break;
                    }
                    case OK: {
                        setSuccess(true);
                        break;
                    }
                    default: {
                        setError('Une erreur est survenue');
                        break;
                    }
                }
                setIsLoading(false);
            });
        }
    }

    return (
        <LoggedInRoute>
            <IonPage>
                <TopBar title={null} />
                {
                    success ? (
                        <Redirect to='/' />
                    ) : (
                        <IonContent>
                            <IonGrid className="container">
                                <IonRow>
                                    <IonCol>
                                        <h2 className="title title--large">Publier un spoil</h2>
                                        {
                                            error && (
                                                <IonCard color="danger">
                                                    <IonCardHeader>
                                                        <IonCardTitle>Oups</IonCardTitle>
                                                    </IonCardHeader>
                                                    <IonCardContent>
                                                        <p>{error}</p>
                                                    </IonCardContent>
                                                </IonCard>
                                            )
                                        }
                                        <form className="form" onSubmit={handleSubmit}>
                                            <div className="form--group">
                                                <label htmlFor="title">Titre</label>
                                                <AsyncSelect
                                                    id="title"
                                                    className="asyncSelect"
                                                    cacheOptions
                                                    defaultOptions
                                                    value={selectedValue}
                                                    getOptionLabel={e => e.title}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={loadOptions}
                                                    onInputChange={handleSelectInputChange}
                                                    onChange={handleSelectChange}
                                                    noOptionsMessage={({ inputValue }) => "Pas de résultat"}
                                                    loadingMessage={({ inputValue }) => 'Changement'}
                                                    placeholder="Sélectionner un titre"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: 'linear-gradient(143.38deg, $red 4.69%, #8D0004 93.75%)'
                                                        }
                                                    })} />
                                            </div>
                                            <div className="form--group">
                                                <label htmlFor="spoil">Ton spoil</label>
                                                <textarea name="spoil" id="spoil" ref={spoilRef} onChange={() => {
                                                    setSpoilLength(spoilRef.current.value.length);
                                                }} ></textarea>
                                                <label className={`counter${spoilLength === 0 ? ' counter--red' : spoilLength > 130 ? ' counter--red' : ''}`}>{spoilLength}/130</label>
                                            </div>
                                            <div className="form--group btn btn--red ">
                                                <input type="submit" value="Envoyer" />
                                            </div>
                                        </form>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonContent>
                    )
                }
                {
                    isLoading && (
                        <Loader type="full" />
                    )
                }
            </IonPage>
        </LoggedInRoute>
    );
}

export default SpoilWriter;