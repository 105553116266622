import { IonContent, IonPage } from "@ionic/react";
import CommingSoon from "../components/CommingSoon";
import TopBar from "../components/TopBar";
import LoggedInRoute from "../tools/LoggedInRoute";

const FeedSettings: React.FC = () => {
    return (
        <LoggedInRoute>
            <IonPage>
                <TopBar title={null} />
                <IonContent fullscreen scrollY={false}>
                    <div className="container page--feed-settings">
                        <CommingSoon title="Bientôt disponible !" type="full" />
                    </div>
                </IonContent>
            </IonPage>
        </LoggedInRoute>
    );
}

export default FeedSettings;